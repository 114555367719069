import React, { useContext } from 'react';

//Contexts
import { AppContext } from "./Auth/contexts/AppContext";

import Grid from '@mui/material/Grid';

//Charts and Widgets
import FlexChart from './Charts/FlexChart';
import FlexCalendar from './common/FlexCalendar';
import RecentOrders from './Dashboard/RecentOrders';
import '../css/react-big-calendar.css';

//let endpoint = "http://localhost:8080/api";
var hostbase = process.env.REACT_APP_ANGULAR_LEGACY_BASE;
var dbendpoint = process.env.REACT_APP_DB_API4;

//MainPage receives props? Let's try without.
const MainPage = () => {
	document.title="Flex System";

	//App Context
	const appContext = useContext(AppContext);
	const { userId, userRole, userPerms } = appContext;

	
	return (
		<div style={{paddingRight:"15px"}} id="mainpagejs">	


			
			<Grid container spacing={1}>


				<Grid item xs={12} md={12} lg={6}>
						<FlexChart
							key={"chart1"}
							//chartid={3}
							datapreloaded={false}
							chartdata={[]}
							chartconfig={{
								ChartType: "salesperdaybar",  //salesperdayline, salesperdaybar
								chartloaded:false, //Allows Flexchart to handle data load or not
								chartdata:[],
								Measurement: "totalsalesperday", //totalsalesperday
								FixedDates: false, //Bool, determines whether we're using dates sent from view or Days (days back)
								Days: 30, //Unused is FixedDates is true
								Markets: "All", //All, Newegg, Newegg Business, Amazon, etc
								Title: "30 Day Sales",
								DisableTitle: false, //Option to hide title
								TitleAlign: "center",
								TickMarkWidthPx: 200, //Set to avoid bottomAxis tickmark labels from overlapping. Uses function to determine output.
								margin:{top: 50, right: 50, bottom: 50, left: 80 },
								chartstyle:{ height: "500px", padding: "20px 5px 40px 5px", display:"block" } //Attempt to change style in chart controller.
							}}
						/>
				</Grid>



				<Grid item xs={12} md={12} lg={6} style={{overflow:"hidden"}}>
					<div style={{height:"500px", padding:"20px 5px 40px 5px", textAlign:"center"}}>
						<h2>
							Recent Orders
						</h2>
						<RecentOrders />
					</div>
				</Grid>

						
					



				{/* Checkin chart - Admin shows all active users in period */}
				{(userRole==='admin') &&
					<Grid item xs={12} md={12} lg={6}>
						<div style={{height:"500px", padding:"20px 5px 40px 5px"}}>
							<FlexChart
							key={"chart2"}
							//chartid={3}
							datapreloaded={false}
							chartdata={[]}
							chartconfig={{
								ChartType: "checkinperdaybar",  //salesperdayline, salesperdaybar, checkinperdaybar
								chartloaded:false, //Allows Flexchart to handle data load or not
								chartdata:[],
								UserIDs: 'All', //'All' string or array of user ids.
								Measurement: "unitsperday", //totalsalesperday
								FixedDates: false, //Bool, determines whether we're using dates sent from view or Days (days back)
								Days: 30, //Unused if FixedDates is true
								//FixedDates:true,
								//StartDate:datetimeafter,
								//EndDate:datetimebefore,
								Markets: "All", //All, Newegg, Newegg Business, Amazon, etc
								Title: "30 Day Checkin",
								DisableTitle: false, //Option to hide title
								TitleAlign: "center",
								TickMarkWidthPx: 120, //Set to avoid bottomAxis tickmark labels from overlapping. Uses function to determine output.
							}}
						/>
						</div>
					</Grid>
				}

				{(userPerms.readCheckinGraph === 1 && userRole !== "admin") && 
					<Grid item xs={12} md={12} lg={(userPerms.readCheckinGraph === 1 && userPerms.readRepairGraph === 1) ? 6 : 12}>
						<div style={{height:"500px", padding:"20px 5px 40px 5px"}}>
							<FlexChart
							key={"chart2"}
							//chartid={3}
							datapreloaded={false}
							chartdata={[]}
							chartconfig={{
								ChartType: "checkinperdaybar",  //salesperdayline, salesperdaybar, checkinperdaybar
								chartloaded:false, //Allows Flexchart to handle data load or not
								chartdata:[],
								UserIDs: [userId], //'All' string or array of user ids.
								Measurement: "unitsperday", //totalsalesperday
								FixedDates: false, //Bool, determines whether we're using dates sent from view or Days (days back)
								Days: ((userPerms.readCheckinGraph ===1 && userPerms.readRepairGraph === 1) ? 30 : 60), //Unused if FixedDates is true
								//FixedDates:true,
								//StartDate:datetimeafter,
								//EndDate:datetimebefore,
								Markets: "All", //All, Newegg, Newegg Business, Amazon, etc
								Title: "30 Day Checkin",
								DisableTitle: false, //Option to hide title
								TitleAlign: "center",
								TickMarkWidthPx: 120, //Set to avoid bottomAxis tickmark labels from overlapping. Uses function to determine output.
							}}
						/>
						</div>
					</Grid>
				}

				

				{/* If Admin, See All Repairs 
						To Do: Configure API to only send back data for the active participants for UserIDs=All
					*/}

				{(userRole==='admin') &&
					<Grid item xs={12} md={12} lg={6}>
						<div style={{height:"500px", padding:"20px 5px 40px 5px"}}>
							<FlexChart
							key={"chart3"}
							//chartid={3}
							datapreloaded={false}
							chartdata={[]}
							chartconfig={{
								ChartType: "repairperdaybar",  //salesperdayline, salesperdaybar, checkinperdaybar
								chartloaded:false, //Allows Flexchart to handle data load or not
								chartdata:[],
								UserIDs: 'All', //'All' string or array of user ids.
								Measurement: "unitsperday", //totalsalesperday
								FixedDates: false, //Bool, determines whether we're using dates sent from view or Days (days back)
								Days: 30, //Unused if FixedDates is true
								//FixedDates:true,
								//StartDate:datetimeafter,
								//EndDate:datetimebefore,
								Markets: "All", //All, Newegg, Newegg Business, Amazon, etc
								Title: "30 Day Repair",
								DisableTitle: false, //Option to hide title
								TitleAlign: "center",
								TickMarkWidthPx: 120, //Set to avoid bottomAxis tickmark labels from overlapping. Uses function to determine output.
							}}
						/>
						</div>
					</Grid>
				}

				
				{(userPerms.readRepairGraph === 1 && userRole !== "admin") &&
					<Grid item xs={12} md={12} lg={(userPerms.readCheckinGraph ===1 && userPerms.readRepairGraph === 1) ? 6 : 12}>
						<div style={{height:"500px", padding:"20px 5px 40px 5px"}}>
							<FlexChart
							key={"chart3"}
							//chartid={3}
							datapreloaded={false}
							chartdata={[]}
							chartconfig={{
								ChartType: "repairperdaybar",  //salesperdayline, salesperdaybar, checkinperdaybar
								chartloaded:false, //Allows Flexchart to handle data load or not
								chartdata:[],
								UserIDs: [userId], //'All' string or array of user ids.
								Measurement: "unitsperday", //totalsalesperday
								FixedDates: false, //Bool, determines whether we're using dates sent from view or Days (days back)
								Days: ((userPerms.readCheckinGraph ===1 && userPerms.readRepairGraph === 1) ? 30 : 60), //Unused if FixedDates is true
								//FixedDates:true,
								//StartDate:datetimeafter,
								//EndDate:datetimebefore,
								Markets: "All", //All, Newegg, Newegg Business, Amazon, etc
								Title: "30 Day Repair",
								DisableTitle: false, //Option to hide title
								TitleAlign: "center",
								TickMarkWidthPx: 120, //Set to avoid bottomAxis tickmark labels from overlapping. Uses function to determine output.
							}}
						/>
						</div>
					</Grid>
				}

			





			</Grid>


			<div style={{textAlign:"center", width:"100%"}}>
				<div style={{maxWidth:"1200px", margin:"auto"}}>
					<FlexCalendar />
				</div>	
			</div>

			


				
			
			{/*
			<div style={{ textAlign: 'center', overflow: "hidden" }}>


				<div style={{ height: (window.innerHeight - 35) + "px" }}>
					<iframe src={hostbase + "/legacy?react=true"} style={{ width: "100%", border: "none" }} height={window.innerHeight - 35}></iframe>
				</div>

			</div>
					*/}
			
			
			
		</div>
		
	)
}


export default MainPage;