//Order Inspection Table

//About: This is a grid with inspections with a heavy dependence on the Inspection redux feature - Dual purpose!

//React & Friends
import React, { useState, useEffect, useContext, useRef } from 'react';
import {useLocation, useHistory} from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import axios from "axios";
import PropTypes from 'prop-types';

//Redux Features
import { useSelector, useDispatch } from 'react-redux';
//ProgressBar
import SaveIcon from '@mui/icons-material/Save';
import PendingIcon from '@mui/icons-material/Pending';
import { ProgressBar } from '../../features/progressbar/ProgressBar';
import {
	newProgress,
	incrementPass,
	incrementFail,
	setProgressTimeout
  } from '../../features/progressbar/progressbarSlice';
//Main Menu
import {
	setCurrentMenuSection,
	setCurrentMenuItem
} from '../../features/mainmenu/mainmenuSlice';
//New Error Message
import { NewErrorMessage } from '../../features/error/NewErrorMessage';
import {
	newErrorMessage,
	setErrorTimeout
} from '../../features/error/errormessageSlice';
// //Inspection Slice
// import { Inspection } from '../../features/orders/Inspection';
// import {
// 	incrementByAmount
// } from '../../features/orders/inspectionSlice';
import InspectionView from "./InspectionView";


//Contexts
//AuthContext
import { AppContext } from "../Auth/contexts/AppContext"
//Error Context - warning, danger, ok, neutral
import ErrorMessage from "../common/ErrorMessage";
import { ErrorContext } from '../common/ErrorContext';

//CSS Styles
import flexstyles from '../../css/FlexCss';
import useClasses from '../../ui/useClasses';
import { useMediaQuery } from "@mui/material";
import { styled } from '@mui/material/styles';

//Datetime formatting
import Moment from 'react-moment';
import 'moment-timezone';

//Common Utilities
import SearchInput from "../common/SearchInput";
import RestrictInputNumber from "../common/RestrictInputNumber";

//MaterialUI
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';

//Tables
import TablePagination from '@mui/material/TablePagination';
import TableSortLabel from '@mui/material/TableSortLabel';
import Checkbox from '@mui/material/Checkbox';

//Icons
import Chip from '@mui/material/Chip';

//Datetime Pickers
//Try new moment adapter:
import dayjs from 'dayjs'; //Used with new datetimepickers
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'; //Possibly our new adapter for mui x 7 datetime

//Export
import ExportCSV from '../common/ExportCSV';


/* ##########################  Configuration  ########################## */

//DB
//Old: var dbendpoint = process.env.REACT_APP_DB_HOSTNAME;
var dbendpoint = process.env.REACT_APP_DB_API4;

//Default Axios Post Options
const defaultpostoptions = {
	withCredentials:true,
	withXSRFToken: true,
	crossDomain:true,
	mode:"no-cors",
	timeout:11800,
};

//Helper Functions
//Have not used sleep just yet - is currently on auto-complete sample
function sleep(delay = 0) {
	return new Promise((resolve) => {
		setTimeout(resolve, delay);
	});
}

//Remove - Useful for completely removing object properties by key. May be used for exports.
function removeProp(obj, key) {
	for (var k in obj) {
		if (k === key) {
			delete obj[key];
			return true;
		} else if (typeof obj[k] === "object") {
			if (removeProp(obj[k], key)) return true;
		}
	}
	return false;
}



//Simple Find Duplicates (simple array of values).
const input = [1,1,2,3,3];
const GetDupeArray = (inputarray) => {
	var results = inputarray.reduce(function(acc, el, i, arr) {
		if (arr.indexOf(el) !== i && acc.indexOf(el) < 0) acc.push(el); return acc;
	  }, []);
	return results;
}
const dupearray = GetDupeArray(input);
//console.log("Duplicates2: "+dupearray); // = 1,3 (actual array == [1, 3])

//Find and return all unique values:
const GetUniqueArray = (inputarray) => {
	return inputarray.filter((x, i, a) => a.indexOf(x) === i);
}


//Remove all instances of string from string:
String.prototype.replaceAll = function (find, replace) {
	var str = this;
	return str.replace(new RegExp(find.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&'), 'g'), replace);
};

/*
//Sorting of arrays by objects:
//localstate.orderby:"SerialNumber" - Used in Item Pricing
pricing.sort(function(a,b){
	//Numeric Values
	if (localstate.orderby==='Cost'){
		return a[localstate.orderby]-b[localstate.orderby];
	} else {
		//Alphabetic
		if (a[localstate.orderby] < b[localstate.orderby]){return -1;}
		if (a[localstate.orderby] > b[localstate.orderby]){return 1;}
		return 0;
	}
});
//Reverse if needed
if (localstate.order==="desc"){
	pricing.reverse();
}
*/


const OrderInspection = (props) => {
	document.title="Inspection";
	const dispatch = useDispatch();
	dispatch(setCurrentMenuSection("Orders"));
	dispatch(setCurrentMenuItem("/orderinspection"));
	//Init with URL search parameters:
	// could be '?Name=Chad'


	//This was likely used in testing. I think safely scrapped:
	// const params = new URLSearchParams(useLocation().search);
	// var idparameter = params.get('ID'); //(is actually InternalID)
	// var search1typeinit = "Name";
	// var search1modeinit = "like";
	// if (!idparameter){idparameter="";}else{search1typeinit="InternalID";search1modeinit="strict"};

	let history = useHistory();
	

	/* App Context */
	/* Allows userperms to be used */
	const appContext = useContext(AppContext);
	const { userPerms, userRole } = appContext;

	/* CSS and Media Queries */
	const classes = useClasses(flexstyles);
	const isPrintView = useMediaQuery("print");


	//Search Configuration:
	const activesearchescount = 8;

	//Search Select Inputs
	const defaultselectinputs = [
		{value:"OrderID", text:"Order #"},
		{value:"Name", text:"Name"},
		{value:"Sku", text:"Sku"},
		{value:"Store", text:"Market"},
		{value:"Address", text:"Address"},
		{value:"City", text:"City"},
		{value:"StateCode", text:"State Code"},
		{value:"PostalCode", text:"Zip"},
		{value:"DateTimeAfter", text:"Date After"},
		{value:"DateTimeBefore", text:"Date Before"},
		{value:"Total >", text:"Total"},
		{value:"Total <", text:" <"},
		{value:"PrintedDate", text:"Print Date"},
		{value:"Refunded", text:"Refunded"}
	]

	
	//Set Input Defaults here so we can used them for useState and ResetSearches:
	const inputdefaults = {
		OrderID:{
			type:"OrderID",
			value: "",
			mode:"like",
			uuid:uuidv4(),			
			container:"240px", //Derives whole searchinput size
			keysize:"125px", //Derives just key size, value size will be whatever is left!
			defaultvalue:"",
			grow:1,
		},
		Name:{
			type:"Name",
			value:"",
			mode:"like",
			uuid:uuidv4(),		
			container:"160px",
			keysize:"80px",
			defaultvalue:"",
			grow:1,
		},
		DateTimeAfter:{
			type:"DateTimeAfter",
			value: (() => {
				// let date = new Date();
				// date.setFullYear(date.getFullYear() - 5); //Set to 5 years ago
				// return date;
				return dayjs().subtract(5, 'year');
			})(),
			mode:"datetimeafter",
			uuid:uuidv4(),
			container:"320px",
			keysize:"110px",
			grow:0,
		},
		DateTimeBefore:{
			type:"DateTimeBefore",
			value: dayjs(), //new Date,
			mode:"datetimebefore",
			uuid:uuidv4(),
			container:"320px",
			keysize:"110px",
			grow:0,
		},
		Sku:{
			type:"Sku",
			value:"",
			mode:"like",
			uuid:uuidv4(),		
			container:"220px",
			keysize:"60px",
			defaultvalue:"",
			grow:1,
		},
		Store:{
			type:"Store",
			value:"",
			mode:"like",
			uuid:uuidv4(),		
			container:"240px",
			keysize:"100px",
			defaultvalue:"",
			grow:1,
		},
		Address:{
			type:"Address",
			value:"",
			mode:"like",
			uuid:uuidv4(),		
			container:"210px",
			keysize:"80px",
			defaultvalue:"",
			grow:1,
		},
		City:{
			type:"City",
			value:"",
			mode:"like",
			uuid:uuidv4(),		
			container:"200px",
			keysize:"70px",
			defaultvalue:"",
			grow:1,
		},
		StateCode:{
			type:"StateCode",
			value:"",
			mode:"like",
			uuid:uuidv4(),		
			container:"150px",
			keysize:"110px",
			defaultvalue:"",
			grow:1,
		},
		PostalCode:{
			type:"PostalCode",
			value:"",
			mode:"like",
			uuid:uuidv4(),		
			container:"160px",
			keysize:"70px",
			defaultvalue:"",
			grow:1,
		},
		"Total >":{
			type:"Total >",
			value:"",
			mode:"greaterthan",
			uuid:uuidv4(),		
			container:"120px",
			keysize:"80px",
			defaultvalue:"",
			grow:1,
		},
		"Total <":{
			type:"Total <",
			value:"",
			mode:"lessthan",
			uuid:uuidv4(),		
			container:"120px",
			keysize:"80px",
			defaultvalue:"",
			grow:1,
		},
		PrintedDate:{
			type:"PrintedDate",
			value:"",
			mode:"exactdate",
			uuid:uuidv4(),		
			container:"210px",
			keysize:"100px",
			defaultvalue:"",
			grow:1,
		},
		Refunded:{
			type:"Refunded",
			value:"",
			mode:"bool",
			uuid:uuidv4(),		
			container:"240px",
			keysize:"100px",
			defaultvalue:"",
			grow:1,
		},
	
	
		//Missing Regular Non-Chip type, non-multiple text selection example.
		//Keep in mind, you may also set a defaultValue for certain form inputs that you'd like to initiate and not have
		//an issue with errors on changing the default value.
	}

	const rowRefs = useRef([]);

	/* ##########################  UseState Variables  ########################## */ 
	const [state, setState] = useState({
		dbreload:true, 		//Use in useEffect to check if we should reload the griditems data. Set to false when we're just updating current view items.
		clearselection:true,//Default clear selection everytime we reload DB. Continuous bulk edits may set this to false between updates.
		griditems:[],		//Empty object
		defaultvalues:[],   //Default values: Useful for initial render.
		totalitems:0,
		page:0, //Assume page 0, or else pagination throws an error.
		order:"desc",
		orderby:"OrderDate",
		selectedcount:0,
		rowsperpage:50,
		selectedindexes:[],
		pendingsaves:false, //Used for parent view - Warnings about unsaved items!
		showaddremovesearch:true,
		searchoptions:{
			//New! Key-Value pair array. Easier to itterate in API.
			searchpairs:{
				searchpair1:inputdefaults.Name,
				searchpair2:inputdefaults.OrderID,
				searchpair3:inputdefaults.DateTimeAfter,
				searchpair4:inputdefaults.DateTimeBefore
			},
			nestedrelationships:{
				
			},
			itemlist:""
		}
	});

	//Clone State! We'll get the view from localstate!
	let localstate = Object.assign({}, state);

	function UpdateState(stateobject) {
		setState(stateobject);
	}


	/* ##########################  Top Button Refs and Functions  ########################## */	 
	//Buttons must exist in order to disable or enable them. Hide buttons with hidden class and ternary.
	const btnSave = useRef();
	const btnPendingSave = useRef();
	const btnAddRow = useRef();
	const btnDeleteSelected = useRef();
	const btnExpandAll = useRef();
	const btnResetSearches = useRef();
	const btnEditSelected = useRef();
	const btnExport = useRef();
	const btnUtilityDrawer = useRef();
	const btnItemList = useRef();
	const btnPrintLabels = useRef();
	const btnEditMode = useRef();

	//Disable Buttons
	const DisableButtons = () => {
		// btnSave.current.setAttribute("disabled", true);
		// btnPendingSave.current.setAttribute("disabled", true);
		// btnAddRow.current.setAttribute("disabled", true);
		// btnDeleteSelected.current.setAttribute("disabled", true);
		// btnExpandAll.current.setAttribute("disabled", true);
		// btnResetSearches.current.setAttribute("disabled", true);
		// btnEditSelected.current.setAttribute("disabled", true);
		// btnExport.current.setAttribute("disabled", true);
		// btnUtilityDrawer.current.setAttribute("disabled", true);
		// btnItemList.current.setAttribute("disabled", true);
		// btnPrintLabels.current.setAttribute("disabled", true);
		// btnEditMode.current.setAttribute("disabled", true);
	}

	//Enable Buttons
	const EnableButtons = () => {
		// btnSave.current.removeAttribute("disabled");
		// btnPendingSave.current.removeAttribute("disabled");
		// btnAddRow.current.removeAttribute("disabled");
		// btnDeleteSelected.current.removeAttribute("disabled");
		// btnExpandAll.current.removeAttribute("disabled");
		// btnResetSearches.current.removeAttribute("disabled");
		// btnEditSelected.current.removeAttribute("disabled");
		// btnExport.current.removeAttribute("disabled");
		// btnUtilityDrawer.current.removeAttribute("disabled");
		// btnItemList.current.removeAttribute("disabled");
		// btnPrintLabels.current.removeAttribute("disabled");
		// btnEditMode.current.removeAttribute("disabled");
	}

	//Reusable test to be used with useState bool variables
	//Invalid for none selected or pending changes
	//Bulk Edit Selected
	//Export Selected
	//Print Selected
	const RejectIfInvalidSelected = (value, fnCallback) => {
		if (localstate.selectedindexes.length===0){
			errors.NewError({errmsg:"No items selected.", errshow:true, errtimeout: 5, errtype:'neutral'});
		} else {
			//If all tests pass, use callback
			fnCallback(value);
		}
	}

	//Edit Mode
	const [editmode, setEditMode] = useState(true); //Default: false
	const EditMode = () => {
		setEditMode(!editmode);
	}


	/* ##########################  Column States - ColStates  ########################## */	 
	//Used for hiding/showing columns. Can access using bracket notation later on! colstate[headCell.id]
	const [colstate, setColState] = useState({
		OrderDate:true, //Encompases other details as well. Not likely good to allow hide?
		OrderItemComparison:true,
	});

	const UpdateColState = (colstate) =>{
		setColState(colstate);
	}

	//Each SearchInput will need a key that is refreshed to force a re-render.
	//Required to drive the control of the SearchInput from the parent.
	const [key1, setKey1] = useState(uuidv4());
	const [key2, setKey2] = useState(uuidv4());
	const [key3, setKey3] = useState(uuidv4());
	const [key4, setKey4] = useState(uuidv4());
	

	/* ##########################  Menus  ########################## */

	/* Column Menu */
	const [showcolumnmenu, setColumnMenu] = useState(null);
	const ShowColumnMenu = (event) => {
		setColumnMenu(event.currentTarget);
	}
	const CloseColumnMenu = () => {
		setColumnMenu(null);
	}
	const ToggleColumn = (key) => {
		let newcolstate = colstate;
		newcolstate[key] = ! newcolstate[key];
		UpdateColState(newcolstate);
	}
	const FlexColumnOption = (props) => {
		let columnvalue = props.value;
		return (
			<React.Fragment>
				{(colstate[columnvalue]) &&
					<FormControlLabel control={<Checkbox defaultChecked onClick={()=>{ToggleColumn(columnvalue)}} />} label={props.label} className={classes.columnselecthover} />
				}
				{(!colstate[columnvalue]) &&
					<FormControlLabel control={<Checkbox onClick={()=>{ToggleColumn(columnvalue)}} />} label={props.label} className={classes.columnselecthover}/>
				}
			</React.Fragment>
		)
	}
	const SetCustomView1 = () =>{
		//Applies a few as set to true.
		let newcolstate = colstate;
		newcolstate['Name'] = true;
		newcolstate['Cost'] = true;
		newcolstate['Margin'] = true;
		UpdateColState(newcolstate);
		CloseColumnMenu();
	}



	/* Export Menu */
	const [showexportmenu, setExportMenu] = useState(null);
	const ShowExportMenu = (event) => {setExportMenu(event.currentTarget);}
	const CloseExportMenu = () => {setExportMenu(null);}


	/* ##########################  Selected Rows  ########################## */
	const SelectRow = (index) => {
		//alert(index);
		if (localstate.selectedindexes.indexOf(index)===-1){
			localstate.selectedindexes.push(index);
			//Check for condition that would check Select All Checkbox - Requires Rerender
			if (localstate.griditems.length === localstate.selectedindexes.length){
				UpdateState(localstate);			
			}
		} else {
			var spliceindex = localstate.selectedindexes.indexOf(index);
			localstate.selectedindexes.splice(spliceindex,1);
			//Check for condition that would un-check Select All Checkbox, just 1 less will do - Rerender
			if (localstate.griditems.length === (localstate.selectedindexes.length+1)){
				UpdateState(localstate);			
			}
		}

		//Provision to close export if nothing is selected
		if (localstate.selectedindexes.length===0){
			setShowExportConfirmation(false);
		}
	}

	const handleSelectAllClick = (event) => {
		//Material UI Checkbox Component won't rerender unless we force it. Set a changed GridKey so that shallow comparison fails.
		var i=0;
		if (event.target.checked) {
			localstate.selectedindexes = [];
			for (i=0; i<localstate.griditems.length; i++){
				localstate.griditems[i].isSelected = true;
				localstate.selectedindexes.push(i);
				localstate.griditems[i].GridKey++;
			}
			UpdateState(localstate);
		} else {
			localstate.selectedindexes = [];
			localstate.selectedcount = 0;
			for (i=0; i<localstate.griditems.length; i++){
				localstate.griditems[i].isSelected = false;
				localstate.griditems[i].GridKey++;
			}
			UpdateState(localstate);
		}
		//Provision to close export if nothing is selected
		if (localstate.selectedindexes.length===0){
			setShowExportConfirmation(false);
		}
	};



	/* ##########################  Search Options  ########################## */
	function debounce(func, wait, immediate) {
		var timeout;
		return function () {
			var context = this, args = arguments;
			var later = function () {
				timeout = null;
				if (!immediate) func.apply(context, args);
			};
			var callNow = immediate && !timeout;
			clearTimeout(timeout);
			timeout = setTimeout(later, wait);
			if (callNow) func.apply(context, args);
		};
	};

	//Used as prop on SearchInput
	const onChangeSearchType = (searchpair, searchnumber) => {
		//Clears out changes because table is about to be reloaded:
		//Not Yet Implemented on this controller: ResetPendingSaves();
		localstate.searchoptions.searchpairs["searchpair" + searchnumber] = searchpair;
		localstate.page = 0;
		localstate.dbreload = true;
		UpdateState(localstate);
	};

	const onChangeSearchValue = debounce(function (searchvalue, searchpair, searchnumber, defaultsearchterm) {
		//Clears out changes because table is about to be reloaded:
		//Not Yet Implemented on this controller:  ResetPendingSaves();
		//Possible search validation here such as proper date format or int/float...
		//For AutoComplete, you will likely need to pull some kind of ID or name before setting the searchvalue!		
		localstate.searchoptions.searchpairs["searchpair" + searchnumber] = searchpair;
		localstate.searchoptions.searchpairs["searchpair" + searchnumber].value = searchvalue;
		localstate.page = 0;
		localstate.dbreload = true;
		UpdateState(localstate);
	}, 1200);

	//Key-Value Inputs
	const [searchinputs, setSearchInputs] = useState({
		show1:true,
		show2:true,
		show3:true,
		show4:true,
		show5:false,
		show6:false,
		show7: false,
		lastsearch: 4
	});




	/* ##########################  Loading and Page Changes  ########################## */
	const handleRequestSort = (event, property) => {
		const isAsc = localstate.orderby === property && localstate.order === "asc";		
		localstate.order = (isAsc ? "desc" : "asc");
		localstate.orderby=property;
		localstate.dbreload=true;
		UpdateState(localstate);
	};

	const handleChangePage = (event, newPage) => {
		localstate.dbreload = true;
		localstate.page = newPage;
		UpdateState(localstate);		
		//
	};

	const handleChangeRowsPerPage = (event) => {
		localstate.dbreload = true;
		localstate.rowsperpage = parseInt(event.target.value, 10);
		localstate.page=0;
		UpdateState(localstate);
	};

	//Error Context
	const errors = useContext(ErrorContext);

	//Load Items
	function LoadItems(){  
		if (localstate.clearselection){
			localstate.selectedindexes = [];
			localstate.selectedcount=0;
		} else {
			//Reset to clear selections on subsequent requests
			localstate.clearselection=true;
		}
		const postdata = {
			searchoptions:{
				limit:localstate.rowsperpage,
				currentsort: localstate.orderby,
				currentsortdir: localstate.order,
				searchpairs:localstate.searchoptions.searchpairs,
				nestedrelationships:localstate.searchoptions.nestedrelationships
			}
		};

		if (itemlistsearch.length>0){
			//Serials exist in serial list.
			postdata.searchoptions.itemlist = itemlistsearch;
		}

		axios.post(dbendpoint+"/orderinspection?page="+(localstate.page+1), postdata, defaultpostoptions).then(res => {
			//Rule #1: API should be setup to send 200 response with status. Merge paginated requests.
			if (res.status===200){
				//If ValidateUser() fails to verify user, it sends back 'login' error. 
				if (res.data.Status==="login"){
					//Not logged in. Reload page causes redirect to /login
					window.location.reload(false);
				}
				//All new API calls should return a status.
				if (res.data.Status==="Success"){
					//We should now have a non-0 result from the API
					//Add variables for use with table
					var resultdata = res.data.pagedata.data;
					for (var i =0; i<resultdata.length; i++){
						//Try: GridKey - Apply GridKey to components: key={row.GridKey}
						//Try: Increment GridKey between rerenders; ie: UpdateState(localstate);
						resultdata[i].GridKey=i;
						resultdata[i].unsaved = false;
						resultdata[i].ExpandRow = false;
						if (localstate.selectedindexes.includes(i)){
							resultdata[i].isSelected = true;
						} else {
							resultdata[i].isSelected = false;
						}

						//Set Defaults for selectables:
						//resultdata[i].SomeSelectableDefault = resultdata[i].SomeSelectable;
						
						//Format Datetimes:
						resultdata[i].OrderDate = dayjs(resultdata[i].OrderDate);
					}
					localstate.griditems = resultdata;
					//Hold items for default values
					localstate.defaultvalues = resultdata;

					localstate.totalitems = res.data.pagedata.total;
					//Data freshly loaded, head off any new requests with this state change. Handle in useEffect?
					//localstate.dbreload = false;
					UpdateState(localstate);
				}
				if (res.data.Status==="Failure"){
					//Failure error
					localstate.griditems=[];
					localstate.totalitems = 0;
					UpdateState(localstate);
					errors.NewError({errmsg:res.data.message, errshow:true, errtimeout: 5, errtype:"neutral"})
				}
			} else {
				//Non-200 message from server.
				errors.NewError({errmsg:"Bad response from server.", errshow:true, errtimeout: 5, errtype:"warning"})
			}
		});
	}


	/* ############### RESIZER ####################*/
	const createResizableColumn = function (col, resizer) {
		// Track the current position of mouse
		let x = 0;
		let w = 0;
	
		const mouseDownHandler = function (e) {
			// Get the current mouse position
			x = e.clientX;
			// Calculate the current width of column
			const styles = window.getComputedStyle(col);
			w = parseInt(styles.width, 10);
			// Attach listeners for document's events
			document.addEventListener('mousemove', mouseMoveHandler);
			document.addEventListener('mouseup', mouseUpHandler);
		};
	
		const mouseMoveHandler = function (e) {
			// Determine how far the mouse has been moved
			const dx = e.clientX - x;
			// Update the width of column
			col.style.width = `${w + dx}px`;
		};
	
		// When user releases the mouse, remove the existing event listeners
		const mouseUpHandler = function () {
			document.removeEventListener('mousemove', mouseMoveHandler);
			document.removeEventListener('mouseup', mouseUpHandler);
		};
		resizer.addEventListener('mousedown', mouseDownHandler);
	};

	const InitColumnResizers = () => {
		//Runs after rerender
		// Query the table
		const table = document.getElementById('resizeMe');
		// Query thead:
		const thead = document.querySelector('thead');
		const cols = thead.querySelectorAll('td');
		// Loop over them
		[].forEach.call(cols, function (col) {
			// Create a resizer element
			const resizer = document.createElement('div');
			resizer.classList.add([classes.resizer]);
			// Set the height
			resizer.style.height = `${table.offsetHeight-2}px`;
			// Add a resizer element to the column
			col.appendChild(resizer);
			createResizableColumn(col, resizer);
		});
	}

	/* ############### END OF RESIZER ####################*/




	/* ##########################  App Init  ########################## */
	//Run once, don't clean up until controller dismount (unchanging init variable)
	const [appinit] = useState(true);
	useEffect(() => {
		document.title = "Inspection";
		//Cleanup
		return function cleanup() {
			dispatch(setProgressTimeout(0));
		}
	}, [appinit]);


	//DB Reloads
	useEffect(() => {
		if (state.dbreload){
			//Avoid duplicate loads.
			localstate.dbreload = false;
			LoadItems();
		} else {
			//console.log("Ignore DB Reload.");
			//Boostrap a resizer for the columns:
			InitColumnResizers();
		}
	},);


	//A Ref stays consistent throughout async function
	const requestcount = useRef(0);

	const SaveChanges = () => {
		//Clean up current errors:
		errors.HideError(errors);
		
		//Configuration
		requestcount.current = 0;
		var timeout = 300; //Seconds before closing final ProgressBar results - To Do: Add option to NOT timeout at all
		var requestinterval = 100; //Milliseconds

		var updatearray = [];
		for (var i = 0; i < localstate.griditems.length; i++) {
			if (localstate.griditems[i].unsaved) {
				updatearray.push(localstate.griditems[i]);
			}
		}

		if (updatearray.length > 0) {
			DisableButtons();
			dispatch(newProgress({
				msg: 'Saving items...',
				show: true,
				settimeout: true, //This option allows for a timeout after the final item is pass/failed
				timeout: timeout, //Initiated on final item 
				type: 'items', //Labels your save types such as items, products, skus, parts
				finished: 0,
				percent: "0%",
				total: updatearray.length,
				pass: 0,
				fail: 0,
				faillist: [], //Can take simple values,
				faillinks: [], //Can take links to things like items or products.
				timeoutid: false,
				errors:[] //Can take strings
			}));
			i = 0;
			var limit = updatearray.length - 1;
			/* INTERVAL */
			var updateitems = setInterval(function () {
				var item = updatearray[i];
				const postdata = {
					item: item
				};
				axios.post(dbendpoint + "/orderinspection/update", postdata, defaultpostoptions).then(res => {
					//Rule #1: API should be setup to send 200 response with status. Merge paginated requests.
					if (res.status === 200) {
						//If ValidateUser() fails to verify user, it sends back 'login' error. 
						if (res.data.Status === "login") {
							//Not logged in. Reload page causes redirect to /login
							window.location.reload(false);
						}
						if (res.data.Status === "Success") {
							//Success response also includes the item!
							//If we sent new rows, we'll need to reference the old ID.
							var itemindex = 0;
							if (res.data.OldID) {
								itemindex = localstate.griditems.map(function (o) { return o.ID; }).indexOf(res.data.OldID);
								localstate.griditems[itemindex].unsaved = false;
								rowRefs.current[itemindex + 'SaveStatus'].classList.remove(classes.unsavedhighlight);
								rowRefs.current[itemindex + 'SaveStatus'].classList.remove(classes.errorhighlight);
								//Set New ID
								localstate.griditems[itemindex].ID = res.data.item.ID;
							} else {
								itemindex = localstate.griditems.map(function (o) { return o.ID; }).indexOf(res.data.item.ID);
								localstate.griditems[itemindex].unsaved = false;
								//Refs allow us to update the grid live!
								rowRefs.current[itemindex + 'SaveStatus'].classList.remove(classes.unsavedhighlight);
								rowRefs.current[itemindex + 'SaveStatus'].classList.remove(classes.errorhighlight);
							}
							dispatch(incrementPass());
						}
						if (res.data.Status === "Failure") {
							//Failure error - To Do: Dispatch failure messages to the ProgressBar
							dispatch(incrementFail());
						}
						requestcount.current++;
						FinalizeRequest(requestcount.current, limit, timeout);

					} else {
						//Non-200 message from server.
						requestcount.current++;
						FinalizeRequest(requestcount.current, limit, timeout);
						dispatch(incrementFail());
					}
				}).catch(err => {
					//Non-200, 500 Error, timeout?
					requestcount.current++;
					FinalizeRequest(requestcount.current, limit, timeout);
					dispatch(incrementFail());
				});
				//If we have looped through all items, clear this interval.
				if (i === limit) {
					clearInterval(updateitems);
				}
				i++;
			}, requestinterval);
		} else {
			errors.NewError({ errmsg: "Nothing to save.", errshow: true, errtimeout: 5, errtype: "neutral" })
			ResetPendingSaves();
		}
	}

	//Clean up View
	const FinalizeRequest = (i, limit, timeout) => {
		//The itterator after responses are incremented to n+1. Reversed below:
		if ((i-1)===limit){
			EnableButtons();
			dispatch(setProgressTimeout(timeout));
			MarkErrorItems();
			btnSave.current.style.display="";
			btnPendingSave.current.style.display="none";
		}
	} 


	//Mark Remaining Items as Warning Items
	const MarkErrorItems = () => {
		for (var i=0; i<localstate.griditems.length; i++){
			if (localstate.griditems[i].unsaved){
				rowRefs.current[i+'SaveStatus'].classList.remove(classes.unsavedhighlight);
				rowRefs.current[i+'SaveStatus'].classList.add(classes.errorhighlight);
			} else {
				//Resolve previously unresolved
				rowRefs.current[i+'SaveStatus'].classList.remove(classes.errorhighlight);
			}
		}
	}


	const ResetPendingSaves = () =>{
		for (var i=0; i<localstate.griditems.length; i++){
			if (localstate.griditems[i].unsaved){
				localstate.griditems[i].unsaved = false;
			}
		}
		EnableButtons();
		btnSave.current.style.display="";
		btnPendingSave.current.style.display="none";
	}


	{/* BULK EDIT DRAWER STATE AND FUNCTIONS */}
	const [showbulkeditdrawer, setShowBulkEditDrawer] = useState(false);
	const btnApplyBulkEdit = useRef();

	const CloseBulkEditDrawer = () =>{
		setShowBulkEditDrawer(false);
		//Reload DB
		localstate.dbreload = true;
		//Update State
		UpdateState(localstate);
	}

	//Bulk edits may incur many different types of changes.
	//Using state variables, we can allow the view to change between them.
	const [changetype, setChangeType] = React.useState('');



	//Try to replace all this?
	//In render: {(changetype==='text')}, etc. NO. We need changetype for when we go back to the DB for the update.


	//// CHANGE TYPES - Controls change type and input viewables
	const [changeistext, setChangeIsText] = React.useState(false);
	const [changeisfloat, setChangeIsFloat] = React.useState(false);
	const [changeissomeselectable, setChangeIsSomeSelectable] = React.useState(false);
	const [changeisdbitem, setChangeIsDBItem] = React.useState(false);
	const [changeisdate, setChangeIsDate] = React.useState(false);
	const [changeisbool, setChangeIsBool] = React.useState(false);


	const [changeisint, setChangeIsInt] = React.useState(false);
	const [changeisstatus, setChangeIsStatus] = React.useState(false);
	



	//// VALUES - Controls input values and value validation
	const changevalue = React.createRef();
	//These 2 refs are used to update a number input (RestrictNumber() will limit what can be put into input box)
	const changevaluefloat = React.createRef();
	const changevalueint = React.createRef();
	const changevalueselectable = React.createRef();
	const [dbitemselection, setDBItemSelection] = React.useState();


	const handleChangeEditType = (event) => {
		//Reset change value
		changevalue.current="";
		//Reset Inputs:
		setChangeIsText(false);
		setChangeIsFloat(false);
		setChangeIsSomeSelectable(false);
		setChangeIsDBItem(false);
		setChangeIsBool(false);

		//Examples
		setChangeIsInt(false);
		setChangeIsStatus(false);
		setChangeIsDate(false);

		//Reset Btn
		btnApplyBulkEdit.current.classList.remove(["MuiButton-containedPrimary"]);
		btnApplyBulkEdit.current.classList.add(["Mui-disabled"]);

		setChangeType(event.target.value);

		if (
			//Text values here.
			event.target.value==="Name"
		) {
			setChangeIsText(true);
		}

		if (event.target.value==="Cost" || event.target.value==="Price"){
			setChangeIsFloat(true);
		}

		if (event.target.value==="SomeSelectable"){
			setChangeIsSomeSelectable(true);
		}

		if (event.target.value==="NewCheckbox"){
			setChangeIsBool(true);
		}

		if (event.target.value==="DBItem"){
			setChangeIsDBItem(true);
		}

		if (event.target.value==="Date"){
			setChangeIsDate(true);
		}
		

		//Unused Examples:
		if (event.target.value==="LotID" || event.target.value==="SkidNumber"){
			setChangeIsInt(true);
		}

		if (event.target.value==="Status"){
			setChangeIsStatus(true);
		}		

		if (event.target.value==="LotID" || event.target.value==="SkidNumber"){
			setChangeIsInt(true);
		}

	};

	const handleChangeBulkValue = (event) => {
		//Currency
		if (changetype === "Cost" || changetype === "Price") {
			var newvalue = RestrictInputNumber(event.target.value, changevalue.current, event, "float");
			if (newvalue) {
				changevalue.current = newvalue;
				changevaluefloat.current.value = newvalue;
			} else {
				if (event.key === "Backspace") {
					//If the newvalue doesn't have a decimal, but the old one did, we try to keep the decimal value by ignoring the ref.
					if ((changevalue.current.indexOf(".") > -1)) {
						changevalue.current = parseFloat(event.target.value).toFixed(2);
					}
				}
			}
		//Integers	
		} else if (changetype==="LotID" || changetype==="SkidNumber") {
			changevalue.current = RestrictInputNumber(event.target.value, changevalue.current, event, "positiveinteger");
			//Updates view:
			changevalueint.current.value = changevalue.current;
		
		} else if (changetype==="NewCheckbox"){
			// placeholder
			changevalue.current = event.target.value;
		} else {
			//Raw Values
			changevalue.current = event.target.value;
		}


		//Verify changes can be saved in current form.
		//Avoid certain items being saved as a blank string:
		if (changetype==="Location" && event.target.value===""){
			btnApplyBulkEdit.current.classList.remove(["MuiButton-containedPrimary"]);
			btnApplyBulkEdit.current.classList.add(["Mui-disabled"]);
			errors.NewError({errmsg:"Value cannot be blank.", errshow:true, errtimeout: 3, errtype:"neutral"});

		//Allow bulk change to be saved
		} else {
			btnApplyBulkEdit.current.classList.remove(["Mui-disabled"]);
			btnApplyBulkEdit.current.classList.add(["MuiButton-containedPrimary"]);
		}
	}

	const handleChangeDBItem = (event, item) => {
		setDBItemSelection(item);
		//Allow bulk change to be saved
		btnApplyBulkEdit.current.classList.remove(["Mui-disabled"]);
		btnApplyBulkEdit.current.classList.add(["MuiButton-containedPrimary"]);
	}

	const handleChangeSomeSelectable = (event) => {
		changevalue.current = event.target.value;
		btnApplyBulkEdit.current.classList.remove(["Mui-disabled"]);
		btnApplyBulkEdit.current.classList.add(["MuiButton-containedPrimary"]);
	}


	//Search for DB Item (autocomplete) - Attempt to make this reusable! Setup endpoints as (env db dbendpoint/table/getautocomplete), see also ApplyBulkEdit
	const [dbitemkey, setDBItemKey] = useState("Name");
	const [opendbitemoptions, openDBItemOptions] = React.useState(false);
	const [dbitemoptions, setDBItemOptions] = React.useState([]);
	const [loadingdbitemoptions, setLoadingDBItemOptions] = useState(false);
	const InitDBItemOptions = () => {
		if (dbitemoptions.length===0){
			//Load (up to limit) options
			DBItemSearch("");
		}
		openDBItemOptions(true);
	}

	const DBItemSearch = debounce(function(searchvalue){
		setLoadingDBItemOptions(true);
		const postdata = {					
			key:dbitemkey,
			search:searchvalue,
			limit:20
		};
		//Provisions to reuse this search:
		var table = "";
		if (changetype==="Product"){
			table="products";
		}
		if (changetype==="DBItem"){
			table="orderinspection";
		}
		//Error such as "TypeError: Cannot read property 'filter' of undefined" means we aren't handling the response correctly or the response is malformed.
		axios.post(dbendpoint+"/"+table+"/getautocomplete", postdata, defaultpostoptions).then(res => {
			if (res.status===200){
				if (res.data.Status==="login"){
					window.location.reload(false);
				} else {
					setDBItemOptions(res.data.items);
					console.log(res.data);
				}
			} else {
				//Non-200 message from server.
				errors.NewError({errmsg:"Bad response from server.", errshow:true, errtimeout: 5, errtype:"warning"})
			}
			setLoadingDBItemOptions(false);
		});
	},600);


	const ApplyBulkEdit = () => {
		var items = [];
		for (var i=0; i<localstate.selectedindexes.length; i++){
			items.push(localstate.griditems[localstate.selectedindexes[i]]);
		}

		//Simple key-value pairs:
		if (changetype==="Name" ||
			changetype==="Price" ||
			changetype==="Cost" ||
			changetype==="SomeSelectable" ||
			changetype==="Date" ||
			changetype==="NewCheckbox"
		){
			for (i=0; i<items.length; i++){
				items[i][changetype] = changevalue.current;
			}
		}

		//Database Item Selection (potential multiple values to change)
		if (changetype==="DBItem"){
			//Place your specific column values here:
			for (i=0; i<items.length; i++){
				items[i]["Name"] = dbitemselection.Name;
				//Example: items[i]["ProductID"] = dbitemselection.ProductID;
			}
		}

		//Reassign other item values if needed before pushing them to postdata.items.
		//Example provision to change Margin if price or cost change
		if (changetype==="Price"){
			for (i=0; i<items.length; i++){
				//items[i].Price = changevalue.current;
				items[i].Margin = items[i].Price - items[i].Cost;
			}
		}

		if (changetype==="Cost"){
			for (i=0; i<items.length; i++){
				//items[i].Cost = changevalue.current;
				items[i].Margin = items[i].Price - items[i].Cost;
			}
		}

		var postdata={
			items:items
		}

		

		axios.post(dbendpoint+"/orderinspection/bulkedititems", postdata, defaultpostoptions).then(res => {
			//Rule #1: API should be setup to send 200 response with status. Merge paginated requests.
			if (res.status===200){
				//If ValidateUser() fails to verify user, it sends back 'login' error. 
				if (res.data.Status==='login'){
					//Not logged in. Reload page causes redirect to /login
					window.location.reload(false);
				}
				if (res.data.Status==='Success'){
					//console.log(res.data);
					if (res.data['SuccessCount']>0){
						errors.NewError({
							errmsg:"Items Changed: "+res.data['SuccessCount']+", Failures: "+res.data['FailCount'],
							errshow:true,
							errtimeout: 5,
							errtype:'ok'})
						}
					}
					
				if (res.data.Status==='Failure'){
					//Failure error
					errors.NewError({errmsg:res.data.message, errshow:true, errtimeout: 5, errtype:'neutral'})
				}
			} else {
				//Non-200 message from server.
				errors.NewError({errmsg:"Bad response from server.", errshow:true, errtimeout: 5, errtype:'warning'})
			}
		});
	}



	//Delete Confirmation and Deletion
	const [showdeleteconfirmation, setShowDeleteConfirmation] = useState(false);
	const [deleteitems, setDeleteItems] = useState([]);
	const DeleteSelectedInit = () =>{
		var deleteitemsarray =[];
		//Reflect items to user for confimation.
		for (var i=0; i<state.selectedindexes.length; i++){
			deleteitemsarray.push(localstate.griditems[state.selectedindexes[i]]);
		}
		setDeleteItems(deleteitemsarray);
		setShowDeleteConfirmation(true);
	}

	const DeleteSelected = () => {
		var finishedrequests = 0;
		for (var i=0; i<localstate.selectedindexes.length; i++){
			if (localstate.griditems[localstate.selectedindexes[i]].hasOwnProperty("PendingItem")){
				//Pending items are simply removed from the view and forgotten.
				localstate.griditems.splice(localstate.selectedindexes[i], 1);
				//Count as finished request
				finishedrequests++;
			} else {
				//Make Delete request to DB
				const postdata = {				
					item:localstate.griditems[localstate.selectedindexes[i]]
				};
				axios.post(dbendpoint+"/orderinspection/delete", postdata, defaultpostoptions).then(res => {
					//No matter the response, we consider the result as a 'finished request'. We can then properly do clean-up.
					finishedrequests++;	
					//Rule #1: API should be setup to send 200 response with status. Merge paginated requests.
					if (res.status===200){
						//If ValidateUser() fails to verify user, it sends back "login" error. 
						if (res.data.Status==="login"){
							//Not logged in. Reload page causes redirect to /login
							window.location.reload(false);
						}
						if (res.data.Status==="Success"){
							//Success response also includes the item!
							//If we're pulling the item out of grid items, we'll use the ID of the item for reference.
							if (res.data.OldID){
								//Since griditems state can be reloaded anytime, we look for the indexOf the ID
								var itemindex = localstate.griditems.map(function(o) { return o.ID; }).indexOf(res.data.OldID);
								localstate.griditems.splice(itemindex, 1);
								//Remove selected index as well?
							} else {
								errors.NewError({errmsg:"Could not delete one or more items.", errshow:true, errtimeout: 8, errtype:"warning"})
							}
						}
						if (res.data.Status==="Failure"){
							//Failure error
							errors.NewError({errmsg:res.data.message, errshow:true, errtimeout: 5, errtype:"neutral"});
							//Need to clear selections as any removed grid items will mess with selectedindexes.
							//Setting selected indexes to a blank array will stall the upper for-loop
							localstate.selectedindexes = [];
							localstate.selectedcount = 0;
							for (var j=0; j<localstate.griditems.length; j++){
								localstate.griditems[j].isSelected = false;
								localstate.griditems[j].GridKey++;
							}
							UpdateState(localstate);
						}
					} else {
						//Non-200 message from server.
						errors.NewError({errmsg:"Bad response from server.", errshow:true, errtimeout: 5, errtype:"warning"})
					}
					//After result from last request, do cleanup.
					if (finishedrequests === localstate.selectedindexes.length) {
						//Clear out all selections! Since checkboxes are controlled only by grid items index, we don't have good
						//tracking on which items are which.
						localstate.selectedindexes = [];
						localstate.selectedcount = 0;
						UpdateState(localstate);
						setShowDeleteConfirmation(false);
					}
				});
			}
		}
	}
	const CancelDelete = () => {
		setShowDeleteConfirmation(false);
	}

	// const GoToItem = (id) => {
	// 	history.push('/boilerplatekeyvalues/'+id)
	// }


	/* ##########################  Cell Interaction  ########################## */

	const onChangeName = (event, index) => {
		if (event.key === "Tab"){
			event.preventDefault();
			//If end of table, go to first
			if (rowRefs.current[(index+1)+"Name"]){
				rowRefs.current[(index+1)+"Name"].focus();
			} else {
				rowRefs.current[("0Name")].focus();
			}
		} else {
			//Update ref
			rowRefs.current[index+"Name"].value=event.target.value;
			rowRefs.current[index+"SaveStatus"].classList.add(classes.unsavedhighlight);
			//Update localstate
			localstate.griditems[index].Name = event.target.value;
			localstate.griditems[index].unsaved = true;
			localstate.pendingsaves = true;
			//Update Save button to show pending saves
			//btnSave.current.classList.add(["MuiButton-containedPrimary"]);
		}
	}

	//Tab Order: Used for horizontal tabbing below.
	//We need an order for horizontal tabbing - attempting to tab to an unavailable column (like a disabled column via colstate) will result in an error
	const taborder = ["Name", "Cost", "Price"];


	//Excel-like functionality for grid
	const HandleKeyDown = (event, index, column) => {
		//Deny Decimals for Integer inputs!
		console.log("Key: "+event.key);
		if (event.key === "." && column==="SomePositiveInteger"){
			console.log("Prevent decimal in integer input");
			event.preventDefault();
		}
		//Handle Tabs!
		if (event.key === "Tab") {
			event.preventDefault();
			//Vertical VS Horizontal Tabbing

			//Vertical Tabbing - Checkboxes, Gross Income, Rates
			//Vertical Tabbing is never subject to the next column not being shown (we always to back to record #1 instead!)
			if (column === "Checkbox") {  //Insert each type of column you want vertically tabbed here: if (column==="Checkbox" || column==="Margin"){ etc
				//If the next row ref exists....
				if (rowRefs.current[(index + 1) + column]) {
					rowRefs.current[(index + 1) + column].focus();
				} else {
					//Go to first element
					rowRefs.current[("0" + column)].focus();
				}
			} else {
				//Horizontal Tabbing - Row Data
				//Horizontal Tabbing is subject to certain columns not being available for selection. (colstate)

				//Get index within tab order:
				var tabindex = taborder.indexOf(column);
				//Increase index until we find the next tab order column
				for (var i = (tabindex + 1); i < taborder.length + 1; i++) {
					//If we're at the last column element, go to the next row's first available column element
					if (i === taborder.length) {
						//Start at beginning of row tab order and reitterate
						i = -1;
						//If next row exists:
						if (rowRefs.current[(index + 1) + column]) {
							index = index + 1;
						} else {
							//Go to first row.
							index = 0;
						}
					} else {
						//If there is another elemet in the taborder.... Continue. Else, go to first column.
						if (taborder[i]) {
							//If that next element is available
							if (colstate[taborder[i]]) {
								rowRefs.current[index + taborder[i]].select();
								break;
							}
						} else {
							//Start at beginning of row tab order and reitterate
							i = -1;
						}
					}
				}
			}
		}
		//Handle Down Arrow
		if (event.key === "ArrowDown"){
			event.preventDefault();
			if (rowRefs.current[(index + 1) + column]) {
				rowRefs.current[(index + 1) + column].focus();
			} else {
				//Go to first element
				rowRefs.current[("0" + column)].focus();
			}
		}
		//Handle Up Arrow
		if (event.key === "ArrowUp"){
			event.preventDefault();
			if (rowRefs.current[(index - 1) + column]) {
				rowRefs.current[(index - 1) + column].focus();
			} else {
				//Go to last element
				var lastelement = localstate.griditems.length-1;
				rowRefs.current[(lastelement + column)].focus();
			}
		}
		//Handle Right Arrow - Special cases:
		/*
		if (event.key === "ArrowRight"){
			event.preventDefault();
			//Same Process as Tab
			var tabindex = taborder.indexOf(column);
			for (var i = (tabindex + 1); i < taborder.length + 1; i++) {
				if (i === taborder.length) {
					i = -1;
					if (rowRefs.current[(index + 1) + column]) {
						index = index + 1;
					} else {
						index = 0;
					}
				} else {
					if (taborder[i]) {
						if (colstate[taborder[i]]) {
							rowRefs.current[index + taborder[i]].select();
							break;
						}
					} else {
						i = -1;
					}
				}
			}
		}
		//Handle Left Arrow
		if (event.key === "ArrowLeft"){
			event.preventDefault();
			var tabindex = taborder.indexOf(column);
			var i;
			var rowincrement=0;
			for (i=(tabindex-1); i>-2; i--){
				if (i===-1){
					rowincrement=-1;
					i=taborder.length; //Go to end of tab order
				} else {
					if (colstate[taborder[i]]) {
						break;
					}
				}
			}
			//If tabindex first and we're in the first row, go to the last element on last row.
			if (tabindex===0 && index===0){
				var lastindex = localstate.griditems.length-1;
				rowRefs.current[lastindex + taborder[i]].select();
			} else {
				rowRefs.current[(index+rowincrement) + taborder[i]].select();
			}
		}
		*/
	}


	const DetectBlankNumber = (event, index, column) => {
		if (event.target.value===""){
			rowRefs.current[index+column].value="0.00";
			localstate.griditems[index][column] = "0.00";
		}
	}

	const DetectBlankNumberInteger = (event, index, column) => {
		if (event.target.value === "") {
			rowRefs.current[index + column].value = "0";
			localstate.griditems[index][column] = "0";
		}
	}

	const DetectBlankNumberFloat = (event, index, column) => {
		if (event.target.value === "") {
			rowRefs.current[index + column].value = "0.00";
			localstate.griditems[index][column] = "0.00";
		} else {
			rowRefs.current[index + column].value = parseFloat(event.target.value).toFixed(2);
			localstate.griditems[index][column] = parseFloat(event.target.value).toFixed(2);
		}
	}

	//Catch-All Method.
	const onChangeValue = (event, index, column) => {
		var oldvalue = localstate.griditems[index][column];
		var newvalue = event.target.value;
		console.log("Event value:"+event.target.value);
		console.log("Raw:"+newvalue);
		if (event.key !== "Tab" && 
			event.key!=="ArrowDown" && 
			event.key!=="ArrowUp" && 
			event.key!=="ArrowLeft" && 
			event.key!=="ArrowRight" && 
			event.key!=="ShiftLeft" && 
			event.key!=="ShiftRight"
			){
			//Conditionals for Types:
			if (column==="Cost"){
				if (!oldvalue){
					oldvalue="0";
				}
				newvalue = RestrictInputNumber(newvalue, oldvalue, event, "float");
				//If new value...
				if (newvalue){
					//If Cost changes, so does Margin
					rowRefs.current[index+"Margin"].textContent = (rowRefs.current[index+"Price"].value - newvalue).toFixed(2);
					localstate.griditems[index].Margin = rowRefs.current[index+"Margin"].textContent;
					//Backspace on a decimal should yield same value for old and new
					//console.log("Oldvalue has decimal:"+oldvalue.indexOf(".")+"   Newvalue has decimal:"+newvalue.indexOf(".")+"   Event key:"+event.key);
					if (oldvalue.indexOf(".")>-1 && newvalue.indexOf(".")===-1 && event.key==="Backspace"){
						//console.log("Scenario1");
						//No need to change front end, simply update griditem
						localstate.griditems[index][column] = newvalue;
					} else if (parseFloat(event.target.value) !== parseFloat(oldvalue)){
						rowRefs.current[index+column].value=newvalue;
						localstate.griditems[index][column] = newvalue;
					}
				} else {
					//Handle possible backspace.
					//We need to update the localstate, but NOT the ref. The input ref can't take values like "2." and messes up the cursor position if you try.
					if (event.key === "Backspace") {
						if ((oldvalue.indexOf(".") > -1)) {
							localstate.griditems[index][column] = parseFloat(event.target.value).toFixed(2);
						}
					}
				}
			}

			//Positive integers don't need decimals! Try to preventDefault on input on keydown.
			if (column==="SomePositiveInteger"){
				//TO DO - UNFINISHED UNTESTED CODE
				//Some forms don't have any input to begin with.
				if (!oldvalue){
					oldvalue="0";
				}
				newvalue = RestrictInputNumber(newvalue, oldvalue, event, "positiveinteger");
				//If new value...
				if (newvalue){
					//If Cost changes, so does Margin
					//rowRefs.current[index+"Margin"].textContent = (rowRefs.current[index+"Price"].value - newvalue).toFixed(2);
					//localstate.griditems[index].Margin = rowRefs.current[index+"Margin"].textContent;

					//Backspace on a decimal should yield same value for old and new
					if ([oldvalue].indexOf(".")>-1 && newvalue.indexOf(".")===-1){
						//No need to change front end, simply update griditem
						localstate.griditems[index][column] = newvalue;
					} else if (parseFloat(event.target.value) !== parseFloat(oldvalue)) {
						rowRefs.current[index+column].value=newvalue;
						localstate.griditems[index][column] = newvalue;
					}
				} else {
					//Handle possible backspace.
					//We need to update the localstate, but NOT the ref. The input ref can't take values like "2." and messes up the cursor position if you try.
					// if (event.key === "Backspace") {
					// 	if ((oldvalue.indexOf(".") > -1)) {
					// 		localstate.griditems[index][column] = parseFloat(event.target.value).toFixed(2);
					// 	}
					// }
				}
			}

			if (column==="Price"){
				if (!oldvalue){
					oldvalue="0";
				}
				newvalue = RestrictInputNumber(newvalue, oldvalue, event, "float");
				if (newvalue){
					//If Price changes, so does Margin
					rowRefs.current[index+"Margin"].textContent = (newvalue - rowRefs.current[index+"Cost"].value).toFixed(2);
					localstate.griditems[index].Margin = rowRefs.current[index+"Margin"].textContent;
					//Backspace on a decimal should yield same value for old and new
					if (oldvalue.indexOf(".")>-1 && newvalue.indexOf(".")===-1 && event.key==="Backspace"){
						//No need to change front end, simply update griditem
						localstate.griditems[index][column] = newvalue;
					} else if (parseFloat(event.target.value) !== parseFloat(oldvalue)){
						rowRefs.current[index+column].value=newvalue;
						localstate.griditems[index][column] = newvalue;
					}
				} else {
					if (event.key === "Backspace") {
						if ((oldvalue.indexOf(".") > -1)) {
							localstate.griditems[index][column] = parseFloat(event.target.value).toFixed(2);
						}
					}
				}
			}
			//Provision for Booleans that require a re-render. Expensive!
			if (column==="SomeBoolean"){
				if (event.target.checked){
					console.log("Checked = true");
					localstate.griditems[index][column] = 1;
					UpdateState(localstate);
				} else {
					console.log("Checked = false");
					localstate.griditems[index][column] = 0;
					UpdateState(localstate);
				}
				
			} else {
				//Selects already render the correct and unsaved result of selecting. (but right now they cause rerender via state.... possible change here.)
				if (column==="NewCheckbox") { //MaterialUI Checkbox
					if (event.target.checked){
						localstate.griditems[index][column] = 1;
					} else {
						localstate.griditems[index][column] = 0;
					}
				} else if (column==="SomeSelectable" || column==="Cost" || column==="Price" || column==="SomePositiveInteger") { 
					//Avoids Refs! Required for numeric input and seletables. Component takes care of view while localstate has yet to update the DB
					localstate.griditems[index][column] = newvalue;
				} else {
					//Update Refs like usual.
					rowRefs.current[index+column].value=newvalue;
					localstate.griditems[index][column] = newvalue;
				}
			}
			rowRefs.current[index+"SaveStatus"].classList.add(classes.unsavedhighlight);
			btnSave.current.style.display="none";
			btnPendingSave.current.style.display="";
			localstate.griditems[index].unsaved = true;
		}
	}

	//Custom Function - Optional
	const onChangeCost = (event, index, column) => {
		if (event.key === "Tab"){
			event.preventDefault();
			//If end of table, go to first
			if (rowRefs.current[(index+1)+column]){
				rowRefs.current[(index+1)+column].focus();
			} else {
				rowRefs.current[("0"+column)].focus();
			}
		} else {
			//If Cost changes, so does Margin
			rowRefs.current[index+"Margin"].value = (rowRefs.current[index+"Price"].value - event.target.value).toFixed(2);
			//Update ref
			rowRefs.current[index+column].value=event.target.value;
			rowRefs.current[index+"SaveStatus"].classList.add(classes.unsavedhighlight);
			//Update localstate
			localstate.griditems[index][column] = event.target.value;
			console.log(localstate.griditems[index].Cost);
		}
	}


	/* ##########################  Button Functions  ########################## */

	const ExpandRowToggle = (index) => {
		localstate.griditems[index].ExpandRow = !localstate.griditems[index].ExpandRow;
		UpdateState(localstate);
	}

	const ExpandAll = () => {
		for (var i=0; i<localstate.griditems.length; i++){
			localstate.griditems[i].ExpandRow = true;
		}
		UpdateState(localstate);
	}

	const ResetSearches = () => {
		//In this case, we reload the page to get rid of any URL param.
		history.push('/orderinspection');
		// //Reset all:
		// for (var i=1; i<7; i++){
		// 	localstate.searchoptions.searchpairs["searchpair"+i].type = "";
		// 	localstate.searchoptions.searchpairs["searchpair"+i].value = "";
		// 	localstate.searchoptions.searchpairs["searchpair"+i].mode = "like";
		// 	localstate.searchoptions.searchpairs["searchpair"+i].uuid = uuidv4();
		// }
		// //Set Defaults:
		// setItemListSearch('');
		// localstate.searchoptions.searchpairs.searchpair1.type = "Name";
		// localstate.dbreload = true;
		// UpdateState(localstate);
	}

	function getRandomInt(max) {
		return Math.floor(Math.random() * Math.floor(max));
	}

	//Export Confirmation and Exports
	const [showexportconfirmation, setShowExportConfirmation] = useState(false);
	//Chooses between "selected" or "searchresults"
	const [exporttype, setExportType] = useState("");
	//Export Message
	const [exportmessage, setExportMessage] = useState("");
	var exportmode = "";
	var exportlimit = 2000;
	var exportfilename = "InspectionCSV";
	
	const InitExport = (exporttype) => {
		if (exporttype==="selected" && localstate.selectedindexes.length===0){
			//Do nothing
		} else {
			//Show export type selection
			setShowExportConfirmation(true);
			//Set export by "selected" or "searchresults"
			setExportType(exporttype);
			if (exporttype==="selected"){
				setExportMessage("");
			}
			if (exporttype==="searchresults"){
				setExportMessage("Exports of search results will be limited to "+exportlimit+" rows.");
			}
		}
	}

	const PrepareExport = (mode) =>{
		//Set global
		exportmode = mode;
		var exportdata = [];
		var clonedata=[];
		if (exporttype==="selected"){
			//Need to DEEP clone:
			var clonedata = JSON.parse(JSON.stringify(localstate.griditems))
			for (var i=0; i<localstate.selectedindexes.length; i++){
				exportdata.push(clonedata[localstate.selectedindexes[i]]);
			}
			Export(exportdata);
		}
		if (exporttype==="searchresults"){
			const postdata = {
				searchoptions:{
					//Set Max Limit Here
					limit:exportlimit,
					currentsort: localstate.orderby,
					currentsortdir: localstate.order,
					searchpairs:localstate.searchoptions.searchpairs
				}
			};
			axios.post(dbendpoint+"/orderinspection?page="+(localstate.page+1), postdata, defaultpostoptions).then(res => {
				if (res.status===200){
					if (res.data.Status==="login"){
						window.location.reload(false);
					}
					if (res.data.Status==="Success"){
						exportdata = res.data.pagedata.data;
						Export(exportdata);
					}
					if (res.data.Status==="Failure"){
						errors.NewError({errmsg:res.data.message, errshow:true, errtimeout: 5, errtype:"neutral"})
					}
				} else {
					errors.NewError({errmsg:"Bad response from server.", errshow:true, errtimeout: 5, errtype:"warning"})
				}
			});
		}
	}

	const Export = (exportdata) => {
		//Remove Metas
		for (var i=0; i<exportdata.length; i++){
			removeProp(exportdata[i], "isSelected");
			removeProp(exportdata[i], "unsaved");
			removeProp(exportdata[i], "ExpandRow");
			removeProp(exportdata[i], "GridKey");
		}

		//Use Export Mode to pare data
		if (exportmode==="simple"){
			for (var i=0; i<exportdata.length; i++){
				removeProp(exportdata[i], "Date");
				removeProp(exportdata[i], "created_at");
				removeProp(exportdata[i], "updated_at");
			}
		}

		if (exportmode==="expanded"){
			for (var i=0; i<exportdata.length; i++){
				removeProp(exportdata[i], "created_at");
				removeProp(exportdata[i], "updated_at");
			}
		}

		if (exportmode==="exhaustive"){
			//Remove nothing - or possibly break down nested relationships
		}
		ExportCSV(exportdata, exportfilename);
		setShowExportConfirmation(false);
	}


	/* ########################## Lower Screen Demo Data/Functions ###### */


	//Basic Select
	const [basicselectvalue, setBasicSelectValue] = React.useState(10);

	const handleChange = (event) => {
		setBasicSelectValue(event.target.value);
	};


	//Autocomplete Simple
	//Example: Products
	const [openproductoptions, openProductOptions] = React.useState(false);
	const [productoptions, setProductOptions] = React.useState([]);
	const [loadingproductoptions, setLoadingProductOptions] = useState(false);
	const [productsearchterm, setProductSearchTerm] = useState("Default Value");
	//For loading single items that do have a value, use LoadItem function in conjunction with: setProductSearchTerm(res.data.item.product.Name);
	const InitProductOptions = () => {
		if (productoptions.length===0){
			ProductSearch("");
		}
		openProductOptions(true);
	}

	const onChangeProductOption = (event, newvalue) =>{
		//Set value for current search
			// Example:
			// localstate.searchoptions.searchpairs.searchpairX.type=autocompletesearchtypeX;
			// localstate.searchoptions.searchpairs.searchpairX.value=newvalue[autocompletesearchtypeX];
			// localstate.dbreload = true;
			// UpdateState(localstate);

		//Or set value for your item:
			//localstate.itemdata['ProductID'] = newvalue['ProductID'];
			// localstate.pendingsaves = true;
			// btnSave.current.disabled = false;
			// btnSave.current.style.color="white";
			// btnSave.current.style.backgroundColor="#01579B";

		//Or just yell at the user, the ProductID they just set
			//alert(newvalue['ProductID']);

		
			
	}

	const ProductSearch = debounce(function(searchvalue){
		setProductSearchTerm(searchvalue);
		setLoadingProductOptions(true);
		const postdata = {					
			search:searchvalue,
			limit:20
		};
		axios.post(dbendpoint+"/items/searchproducts", postdata, defaultpostoptions).then(res => {
			if (res.status===200){
				if (res.data.Status==="login"){
					window.location.reload(false);
				}
				if (res.data.Status==="Success"){
					setProductOptions(res.data.items);
				}
			} else {
				//Non-200 message from server.
				errors.NewError({errmsg:"Bad response from server.", errshow:true, errtimeout: 5, errtype:"warning"})
			}
			setLoadingProductOptions(false);
		});
	},600);

	const [basicselectdbvalue, setBasicSelectDBValue] = useState("Default basic select value");



	//Let's try to set a default value for our AutoComplete
	const [testDBAC, setDBAC] = useState('Some UseState Default');

	const onDBAutoCompleteChange = (newvalue, searchkey) =>{
		//We can parse results by using keys - this makes the function reusable so we can insert many DBAutoComplete Components
		if (searchkey==="Vendor"){
			console.log("Set new value: "+newvalue.Vendor);
			setDBAC(newvalue.Vendor);
		}
		
	}

	

	/* ##########################  TABLE COLUMN HEADERS  ########################## */
	/* ##########################  Column Configuration  ########################## */
	const headCells = [
		//Be sure to adjust widths for cells as well.
		{ id: "OrderDate", numeric: false, label: "Order Details", align:"left", allowsort:true, style:{width:"400px"}},
		{ id: "OrderItemComparison", numeric: false, label: "Comparison", align:"center", allowsort:true, style:{}}
	];
	

	function Inspect(props) {
		const [order, updateOrder] = useState(props.order);

		return(
			<a onClick={()=>updateOrder({
				InternalID:666
			})}>{order.InternalID}</a>
		)
	}
	function EnhancedTableHead(props) {
		const { classes, onSelectAllClick, order, onRequestSort } = props;
		const createSortHandler = (property) => (event) => {
			onRequestSort(event, property);
		};
	
		return (
			<thead style={{display:"table-header-group"}}>
				<tr style={{border:"1px solid #CCC",
							backgroundColor:"#DDD"}}>
					<td style={{width: "14px", padding:"none", display:"table-cell", padding:"2px 4px 2px 5px"}}>

						<Checkbox
							className={classes.gridcheckbox}
							disableRipple
							color="default"
							defaultChecked={localstate.griditems.length === localstate.selectedindexes.length}
							checkedIcon={<span className={classes.icon+" "+classes.checkedIcon} />}
							icon={<span className={classes.icon} />}
							onChange={onSelectAllClick}
						/>

					</td>
					{/* Map remaining table headers */}
					{headCells.map((headCell) =>
						colstate[headCell.id] && (
							<td
								key={headCell.id}
								align={headCell.align}
								style={headCell.style}
							>
								{(headCell.allowsort) &&
									<TableSortLabel
										active={localstate.orderby === headCell.id}
										direction={localstate.orderby === headCell.id ? order : "asc"}
										onClick={createSortHandler(headCell.id, headCell.allowsort)}
										hideSortIcon
									>
										{/* This is a conditional for headCell where the id is "Name". This allows us to put a spacer in here for the expand icon button */}
										{/* The expand button is optional, this can be removed if needed! */}
										{(headCell.id === "Name") &&
											<div style={{ width: "30px", display: "inline-block" }}></div>
										}
										{/* If current sort, show bold label */}
										{(localstate.orderby === headCell.id)
											? <span style={{ fontWeight: "bold" }}>{headCell.label}</span>
											: <span>{headCell.label}</span>
										}
									</TableSortLabel>
								}
								{(!headCell.allowsort) &&
									<span>{headCell.label}</span>
								}
							</td>
						)
					)}
				</tr>
			</thead>
		);
	}

	EnhancedTableHead.propTypes = {
		classes: PropTypes.object.isRequired,
		numSelected: PropTypes.number.isRequired,
		onRequestSort: PropTypes.func.isRequired,
		onSelectAllClick: PropTypes.func.isRequired,
		order: PropTypes.oneOf(["asc", "desc"]).isRequired,
		orderBy: PropTypes.string.isRequired,
		rowCount: PropTypes.number.isRequired,
	};

	{/* Utility Drawer state and functions */}
	const [showutilitydrawer, setShowUtilityDrawer] = useState(false);

	const CloseUtilityDrawer = () =>{
		setShowUtilityDrawer(false);
	}

	{/* Item List - General purpose boilerplate */}
	const [showitemlist, setShowItemList] = useState(false);
	//We keep our original item list intact, use itemlistsearch for new DB reload
	const [itemlistsearch, setItemListSearch] = useState("");
	const [dupeitems, setDupeItems] = useState('');
	const CloseItemList = () =>{
		//Get unique items
		const result = localstate.searchoptions.itemlist.split(/\r?\n/).filter(element => element);
		const newitemlist = GetUniqueArray(result);
		setItemListSearch(newitemlist);
		setShowItemList(false);

		//Reload DB
		localstate.dbreload = true;
		//Update State
		UpdateState(localstate);
	}
	const onChangeItemList = (event) => {
		//Optional check for dupes:
		//Bust apart by line breaks!
		//Let's start sending arrays to PHP Laravel instead where possible since we're already
		//going to do work here before the API call.

		//If there is a line break at the end of the list (blank new line), you may end up with a falsy array element at the end.
		//Filter creates a new array based on passing a test function (true or false).
		//By passing a falsy array element (undefined), it won't return that element in the result array.
		//Split by Line Breaks, then filter.
		const result = event.target.value.split(/\r?\n/).filter(element => element);
		//Find Dupes, let the user know there are dupes.
		const dupes = GetDupeArray(result);
		if (dupes.length>0){
			setDupeItems(dupes);
			console.log(dupes);
		} else {
			setDupeItems('');
		}
		localstate.searchoptions.itemlist = event.target.value;
		//setItemList(event.target.value);
	}

	{/* Print Labels */}
	const PrintLabels = () =>{
		var itemids = [];
		//Grab all IDs
		for (var i=0; i<localstate.selectedindexes.length; i++){
			itemids.push(localstate.griditems[localstate.selectedindexes[i]]['ID']);
		}

		var params = {
			itemids:JSON.stringify(itemids),
			labeltype:'basiclabel',
			autoclose:false,
			seriallist:'',
			orderby:localstate.orderby,
			order:localstate.order
		};
	
		// Creating a form
		var form = document.createElement("form");
		form.setAttribute("method","post");
		form.setAttribute("action","../v3/labels");
		form.setAttribute("target", "NewLabel");
		for (var i in params) {
			if (params.hasOwnProperty(i)){
				// Creating the input
				var input = document.createElement('input');
				input.type='hidden';
				input.name=i;
				input.value=params[i];

				// Attach input to form
				form.appendChild(input);
			}
		}
		document.body.appendChild(form);
		form.submit();
	}


	//Some requests might take longer
	const longpostoptions = {
		withCredentials:true,
		withXSRFToken: true,
		crossDomain:true,
		mode:"no-cors",
		timeout:20000,
	};

	const UpdateRefunds = (itteration) => {
		const postdata = {
			itteration:itteration,
			limit:50
		};
		axios.post(dbendpoint+"/utility/updaterefunds", postdata, longpostoptions).then(res => {
			//API should be setup to send 200 response with status. Merge paginated requests.
			if (res.status===200){
				//If ValidateUser() fails to verify user, it sends back 'login' error. 
				if (res.data.Status==="login"){
					//Not logged in. Reload page causes redirect to /login
					window.location.reload(false);
				}
				//All new API calls should return a status.
				if (res.data.Status==="Continue"){
					console.log(res);
					errors.NewError({errmsg:"Success: "+res.data.SuccessCount+"    Failures: "+res.data.FailCount+"   Continuing...."+res.data.Itteration, errshow:true, errtimeout: 5, errtype:"OK"})
					UpdateRefunds(res.data.Itteration+1);
					//Let's employ a timeout so we aren't hammering the system.
					//Doesn't work. setTimeout(UpdateDiscounts(res.data.Itteration+1), 5000);
				}
				if (res.data.Status==="Finished"){
					errors.NewError({errmsg:"Finished!", errshow:true, errtimeout: 36000, errtype:"OK"})
					
				}
				if (res.data.Status==="Failure"){
					//Failure error
					errors.NewError({errmsg:res.data.message, errshow:true, errtimeout: 5, errtype:"neutral"})
				}
			} else {
				//Non-200 message from server.
				errors.NewError({errmsg:"Bad response from server.", errshow:true, errtimeout: 5, errtype:"warning"})
			}
		});
	}






	/* 
	                                                                                         
         _/_/_/        _/_/_/_/       _/      _/       _/_/_/        _/_/_/_/       _/_/_/    
        _/    _/      _/             _/_/    _/       _/    _/      _/             _/    _/   
       _/_/_/        _/_/_/         _/  _/  _/       _/    _/      _/_/_/         _/_/_/      
      _/    _/      _/             _/    _/_/       _/    _/      _/             _/    _/     
     _/    _/      _/_/_/_/       _/      _/       _/_/_/        _/_/_/_/       _/    _/      
                                                                                         
                                                                                         
 	*/

	
	/* ##########################  Render Function  ########################## */
	/* General rules: The /App allows us to use 100% of the width here. We can also allow each controller to set their own min width, ie: minWidth: "350px" below */
	return (
		<LocalizationProvider dateAdapter={AdapterDayjs}>
			<div style={{padding:"8px", overflow:"auto", minWidth:"350px"}}>
				{/* Utility Drawer, good for search options */}
				<Drawer open={showutilitydrawer} style={{ width: "600px" }}>
					<div style={{height:"47px"}}></div>
					<Typography variant="h4" gutterBottom align="center">
						Utility Drawer
					</Typography>
					<div style={{ width: "400px", padding: "10px" }}>
						<Typography variant="subtitle1" gutterBottom>
							You may want to add some instructional text here.<br></br>
						</Typography>

						{/* You may want access to errors here */}
						{(errors.currenterror.errshow) &&
							<div style={{ textAlign: "center", height: "25px", fontSize: "12px" }}>
								<ErrorMessage />
							</div>
						}
						<NewErrorMessage />

						<Button
							className={classes.bluebtn}
							color="primary" variant="contained"
							onClick={() => CloseUtilityDrawer()}>
							Close
						</Button>
						
					</div>
				</Drawer>


				{/* Item List - used to load only items placed in text area. */}
				<Drawer open={showitemlist} style={{ width: "600px" }}>
					<div style={{height:"47px"}}></div>
					<Typography variant="h4" gutterBottom align="center">
						Item List - Example: Names
					</Typography>
					<div style={{ width: "400px", padding: "10px" }}>
						<Typography variant="subtitle1" gutterBottom>
							Add names here:<br></br>
						</Typography>

						{/* You may want access to errors here */}
						{(errors.currenterror.errshow) &&
							<div style={{ textAlign: "center", height: "25px", fontSize: "12px" }}>
								<ErrorMessage />
							</div>
						}

						<TextareaAutosize style={{ width: "100%" }} minRows={10} placeholder="Paste names from spreadsheet here."
							onChange={(event) => onChangeItemList(event)}
							defaultValue={localstate.searchoptions.itemlist}
						/>

						<Button
							className={classes.bluebtn}
							color="primary" variant="contained"
							onClick={() => CloseItemList()}>
							Close
						</Button>

						{(dupeitems.length>0) &&
							<div>
								Duplicates found: 
									{dupeitems.map(function (dupe, i) {
										console.log('test');
										return <li key={i}>{dupe}</li>
									})}
							</div>
						}
						
					</div>
				</Drawer>



				{/* Bulk Edit Drawer */}
				<Drawer open={showbulkeditdrawer} style={{ width: "600px" }}>
					<div style={{height:"47px"}}></div>
					<Typography variant="h4" gutterBottom align="center">
						Bulk Edit Selected Items
					</Typography>
					<div style={{ width: "400px", padding: "10px" }}>
						<Typography variant="subtitle1" gutterBottom>
							Changes apply to all checkbox selected items in the list view. You may perform multiple changes before returning to the list. Select change type to get started.<br></br>
						</Typography>

						{/* You may want access to errors here - create parent container to hold position for you. */}
						<div style={{height:"25px"}}>
							{(errors.currenterror.errshow) &&
								<div style={{ textAlign: "center", height: "25px", fontSize: "12px" }}>
									<ErrorMessage />
								</div>
							}	
						</div>
						

						<FormControl className={classes.formControl} style={{ width: "300px" }} variant="standard">
							<InputLabel id="Change type">Change Type</InputLabel>
							<Select
								labelId="change-type"
								id="change-type"
								value={changetype}
								onChange={handleChangeEditType}
							>
								<MenuItem value={'Name'}>Name</MenuItem>
								<MenuItem value={'Cost'}>Cost</MenuItem>
								<MenuItem value={'Price'}>Price</MenuItem>
								<MenuItem value={'SomeSelectable'}>Some Selectable</MenuItem>
								<MenuItem value={'DBItem'}>DB Item (Demo of Name lookup)</MenuItem>		
								<MenuItem value={'NewCheckbox'}>New Checkbox</MenuItem>						
							</Select>
						</FormControl>

						{/* To do: Set change values. Most are just text, so we'll start with that: */}
						{(changeistext) &&
							<div>
							<TextField variant="standard" style={{ width: "300px" }} required id="standard-required" defaultValue="" onChange={handleChangeBulkValue} />
							</div>
						}

						{(changeisstatus) &&
							<FormControl className={classes.formControl} style={{width:"300px"}} variant="standard">
							<InputLabel id="demo-simple-select-label">Select Type</InputLabel>
							<Select
								labelId="demo-simple-select-label"
								id="demo-simple-select"
								onChange={handleChangeBulkValue}
							>
								<MenuItem value={"Checked In"}>Checked In</MenuItem>
								<MenuItem value={"Sold"}>Sold</MenuItem>
								<MenuItem value={"Scrap"}>Scrap</MenuItem>
								<MenuItem value={"Returned to Vendor"}>Returned to Vendor</MenuItem>
								<MenuItem value={"Undetermined or Missing"}>Undetermined or Missing</MenuItem>
							</Select>
							</FormControl>
						}

						{/* Float: Cost, Price */}
						{(changeisfloat) &&
							<div>
								<TextField variant="standard" type="number" step="0.01"  
									inputRef={el => changevaluefloat.current = el} style={{ width: "300px" }} 
									required id="standard-required" defaultValue="Value" 
									onKeyUp={(event)=>{handleChangeBulkValue(event)}} 
								/>
							</div>
						}

						{/* EXAMPLE: Integer: LotID or SkidNumber */}
						{(changeisint) &&
							<div>
								<TextField variant="standard" type="number" step="1"  
								inputRef={el => changevalue.current = el}
								style={{ width: "300px" }}
								required 
								id="standard-required"
								defaultValue=""
								onKeyUp={(event)=>{handleChangeBulkValue(event)}} />
							</div>
						}


						{/* Change is Some Selectable SomeSelectable */}
						{(changeissomeselectable) &&
							<div>
								<FormControl variant="standard" className={classes.formControl}>
									{/* <InputLabel id="demo-controlled-open-select-label">Optional Label</InputLabel> */}
									{/* Need: Set default value if row.SomeSelectable is null. */}
									{/* Why: Some of the values may be momentarily null for some reason - DB fetch, rerender? */}

									{/* Override icon prop class to our custom 'nodisplay' to remove arrowdown icon for this select */}
									{/* Override select prop class with new padding specs */}

									<Select
										defaultValue={""}
										onChange={(event) => handleChangeSomeSelectable(event)}
										classes={{
											//icon: classes.nodisplay,
											//select: classes.selectpadding
										}}
										style={{ width: "300px" }}
									>
										<MenuItem value="">
											<em>None (blank string)</em>
										</MenuItem>
										<MenuItem value={10}>Ten (integer)</MenuItem>
										<MenuItem value={"A"}>
											<Chip size="small" label="A" clickable className={classes.gradea} />
										</MenuItem>
										<MenuItem value={"B"}>
											<Chip size="small" label="B" clickable className={classes.gradeb} />
										</MenuItem>
										<MenuItem value={"C"}>
											<Chip size="small" label="C" clickable className={classes.gradec} />
										</MenuItem>
										<MenuItem value={"Bad"}>
											<Chip size="small" label="Bad" clickable className={classes.gradebad} />
										</MenuItem>
										<MenuItem value={"Repair"}>
											<Chip size="small" label="Repair" clickable className={classes.graderepair} />
										</MenuItem>
										<MenuItem value={"Scrap"}>
											<Chip size="small" label="Scrap" clickable className={classes.gradescrap} />
										</MenuItem>
									</Select>
								</FormControl>
							</div>
						}


						{/* DB Item (Autocomplete) */}
						{(changeisdbitem) &&
							<div>
								<Autocomplete freeSolo forcePopupIcon={false} disableClearable style={{ width: "300px", padding:"0px" }}
									open={opendbitemoptions} onOpen={() => { InitDBItemOptions (); }} onClose={() => { openDBItemOptions(false); }}
									//Not sure how to avoid passing event, then newvalue... seems to break when removing event.
									//* onChange={(event, newValue) => onChangeOption1(event, newValue)} */}
									onChange={(event, newValue) => handleChangeDBItem (event, newValue)}
									onInputChange={(event) => DBItemSearch(event.target.value)}
									isOptionEqualToValue={(option, value) => option[dbitemkey] === value}
									getOptionLabel={(option) => option[dbitemkey]}
									/* Work-around for when there are no options and you get errors for getOptionLabel:
										getOptionLabel={
											(option) => {
												if (option){
													return option["Name"];
												} else {
													return "";
												}
											}
										}
									*/
									options={dbitemoptions}
									loading={loadingdbitemoptions}
									renderInput={(params) => (
										<TextField variant="standard"
											className={classes.autocompleteinput}
											style={{padding:"0px"}}
											{...params}
											InputProps={{
												disableUnderline: false,
												...params.InputProps,
												autoComplete: "new-password",
												endAdornment: (
													<React.Fragment>
														{loadingdbitemoptions ? <CircularProgress color="inherit" size={20} /> : null}
														{params.InputProps.endAdornment}
													</React.Fragment>
												),
											}}
										/>
									)}
								/>
							</div>
						}

						{/* Date */}
						{(changeisdate) &&
							<FormControl className={classes.formControl} style={{width:"300px"}}>
							<TextField variant="standard"
								id="datetime-local"
								type="datetime-local"
								defaultValue=""
								onChange={handleChangeBulkValue}
								sx={{ width: 300 }}
								InputLabelProps={{
								shrink: true,
								}}
							/>
							</FormControl>
						}


						{/* Change Is Bool */}
						{(changeisbool) &&
							<div style={{marginTop:"20px"}}>
								<FormControl>
									<FormLabel id="demo-radio-buttons-group-label">Set Boolean Status (customizable)</FormLabel>
									<RadioGroup
										aria-labelledby="demo-radio-buttons-group-label"
										defaultValue="0"
										name="radio-buttons-group"
										onChange={handleChangeBulkValue}
									>
										<FormControlLabel value="0" control={<Radio />} label="Disable" />
										<FormControlLabel value="1" control={<Radio />} label="Enable" />
									</RadioGroup>
								</FormControl>
							</div>
						}

						<Button
							className={classes.bluebtn}
							color="primary" variant="contained"
							onClick={() => ApplyBulkEdit()}
							ref={el => btnApplyBulkEdit.current = el}>
							Apply Change
						</Button>

						<Button
							className={classes.bluebtn}
							color="primary" variant="contained"
							onClick={() => CloseBulkEditDrawer()}>
							Close
						</Button>
						
					</div>
				</Drawer>




				{/* Standard Page Header with right floated error message space */}
				<div style={{ minHeight: "50px", paddingTop:"5px" }}>
					<Grid container justifyContent="space-between">
						<Grid item xs={12} sm={6} md={3} order={{ xs: 3, sm: 3, md: 1 }} style={{padding:"5px", minWidth:"270px"}}>{(errors.currenterror.errshow) &&
							<ErrorMessage />
						}</Grid>
						<Grid item xs={12} sm={6} md={3} order={{ xs: 1, sm: 1, md: 2 }} style={{textAlign:"center", margin:"auto", padding:"5px"}}><h2>Order Inspection</h2></Grid>
						<Grid item xs={12} sm={6} md={3} order={{ xs: 2, sm: 2, md: 3 }} style={{padding:"5px", minWidth:"270px"}}>
							<div style={{float:"right"}}>
								<ProgressBar />
								<NewErrorMessage />
							</div>
						</Grid>
					</Grid>
				</div>


				{/* /* ##########################  Search Inputs  ########################## */}
				{/* Search Tools should: Fall in-line, stack, have padding-right of 15px, 300px wide.*/}
				{/* CHOOSE between AutoCompletes OR Key-Value searches. Helps keep interface looking CLEAN. */}

	{/* Inputs can be Grouped inside of new flex containers: */}
				{(!isPrintView) &&
					<div style={{ display: "flex", flexGrow: "1", flexDirection: "row", flexWrap: "wrap" }}>
						{/* Key-Value Searches - Optionally add a grid for better resizing look */}
						{(searchinputs.show1) &&
							<SearchInput
								searchinput={1}
								key={key1}
								localstate={state}
								inputdefaults={inputdefaults}
								onChangeSearchType={onChangeSearchType}
								onChangeSearchValue={onChangeSearchValue}
								selectinputs={defaultselectinputs}
							/>
						}
						{(searchinputs.show2) &&
							<SearchInput
								searchinput={2}
								key={key2}
								localstate={state}
								inputdefaults={inputdefaults}
								onChangeSearchType={onChangeSearchType}
								onChangeSearchValue={onChangeSearchValue}
								selectinputs={defaultselectinputs}
							/>
						}
						{(searchinputs.show3) &&
							<SearchInput
								searchinput={3}
								key={key3}
								localstate={localstate}
								inputdefaults={inputdefaults}
								onChangeSearchType={onChangeSearchType}
								onChangeSearchValue={onChangeSearchValue}
								selectinputs={defaultselectinputs}
							/>
						}
						{(searchinputs.show4) &&
							<SearchInput
								searchinput={4}
								key={key4}
								localstate={localstate}
								inputdefaults={inputdefaults}
								onChangeSearchType={onChangeSearchType}
								onChangeSearchValue={onChangeSearchValue}
								selectinputs={defaultselectinputs}
							/>
						}
					</div>
				}
				
				{/* End of Search Inputs */}


				{/* Top Buttons & Pagination */}
				{(!isPrintView) &&
					<React.Fragment>
						<div style={{ height: "5px" }}>&nbsp;</div>
						<div>
							{(localstate.totalitems > 0) &&
								<TablePagination className={classes.paginationalign}
									SelectProps={
										(editmode && localstate.rowsperpage>=100) ? { disabled: true } : { disabled: false }
									}
									style={{ display: "inline-flex", float: "right" }}
									component="div"
									count={localstate.totalitems}
									page={localstate.page}
									onPageChange={handleChangePage}
									rowsPerPage={localstate.rowsperpage}
									onRowsPerPageChange={handleChangeRowsPerPage}
									rowsPerPageOptions={[50, 100, 250]}
								/>
							}
						</div>
					</React.Fragment>
				}
				{/* End of Top Buttons & Pagination */}


				{/* Add container for overflow scroll bars - Allows us to set a min width for the page (better looking in many cases), and freeze controls at the top and bottom for ease of use. */}
				<div className={classes.flexgridcontainerA}>
					{/* ##########################  Start of Table  ########################## */}
					<table id="resizeMe" aria-label="caption table" size="small" className={classes.flexgrid} style={{minWidth:"100%", borderCollapse:"collapse", borderColor:"grey"}}>
						<EnhancedTableHead
							numSelected={localstate.selectedcount} 
							classes={classes}
							order={localstate.order}
							orderBy={localstate.orderby}
							onSelectAllClick={handleSelectAllClick}
							onRequestSort={handleRequestSort}
							rowCount={state.griditems.length}
						/>
						{/* /* ##########################  Row Design  ########################## */	 }
						{/* If DB reload, don't allow view of table. */}
						{(!localstate.dbreload) &&
							<tbody style={{ display: "table-row-group" }}>
								{(localstate.griditems.length > 0) &&
									localstate.griditems.map((row, index) => {
										//Create all-new refs on each render. Helps avoid issues with grid states.
										rowRefs.current[index + "Checkbox"] = React.createRef();
										rowRefs.current[index + "SaveStatus"] = React.createRef();
										rowRefs.current[index + "Name"] = React.createRef();
										rowRefs.current[index + "Cost"] = React.createRef();
										rowRefs.current[index + "Price"] = React.createRef();
										rowRefs.current[index + "Margin"] = React.createRef();
										rowRefs.current[index + "SomePositiveInteger"] = React.createRef();
										rowRefs.current[index + "SomeBoolean"] = React.createRef();
										return (
											<React.Fragment key={row.InternalID}>
												<tr	className={classes.flexgridrownohover}>
													{/* Checkbox - Requires inner div to change background color with SaveStatus */}
													<td style={{ verticalAlign: "top" }} ref={el => rowRefs.current[index + "SaveStatus"] = el}>
														<div style={{ padding: "3px 4px 1px 4px" }}>
															{/*	MaterialUI Checkbox will pass a shallow comparison between UpdateState(s). Be sure GridKey changes if it needs to be rerendered such as selectall.	*/}
															{/* Don't show checkbox if this is a PendingItem */}
														{(!row.PendingItem) &&
																<Checkbox
																	key={row.GridKey}
																	inputRef={el=>rowRefs.current[index+"Checkbox"]=el} 
																	className={classes.gridcheckbox}
																	color="default"
																	defaultChecked={localstate.griditems[index].isSelected ? true : false}
																	checkedIcon={<span className={classes.icon+" "+classes.checkedIcon} />}
																	icon={<span className={classes.icon} />}
																	onKeyDown={(event) => HandleKeyDown(event, index, "Checkbox")}
																	onChange={() => SelectRow(index)}
																/>
															}

														</div>
													</td>

													
													{/* OrderDate (Includes other details as well) */}
													{(colstate.OrderDate) &&
														<td className={classes.flexgridinputcontainer} style={{maxWidth:"200px", padding:"8px"}}>
															<b><a target="_blank" href={"/order/" + row.InternalID}>{row.OrderID}&nbsp;</a></b><br></br>
															{dayjs(row.OrderDate).format("MMMM D, YYYY [at] h:mma")}<br></br>
															{(row.SFullName) &&
																<>{row.SFullName}<br></br></>
															}
															{(!row.SFullName && row.SFirstName) &&
																<>{row.SFirstName} {row.SLastName}<br></br></>
															}
															{(!row.SFullName && !row.SFirstName) &&
																<>{row.BFirstName} {row.BLastName}<br></br></>
															}

															{(row.SLine1 && row.SCity && row.SStateCode && row.SPostalCode) &&
																<>
																	{row.SLine1}<br></br>
																	{row.SCity}{(row.SCity ? "," : "")} {row.SStateCode} {row.SPostalCode.substring(0, 5)}
																</>
															}
															{(!row.SLine1 && row.BLine1 && row.BCity && row.BStateCode && row.BPostalCode) &&
																<>
																	{row.BLine1}<br></br>
																	{row.BCity}{(row.BCity ? "," : "")} {row.BStateCode} {row.BPostalCode.substring(0, 5)}
																</>
															}
															<br></br>

														</td>
													}

													{/* OrderDate (Includes other details as well) */}
													{(colstate.OrderItemComparison) &&
														<td style={{whiteSpace:"normal", minWidth:"920px"}}>
															<div style={{padding:"10px", maxWidth:"920px"}}>
																{/* <b>{row.OrderID}</b><br></br> */}
																{/* <Inspection internalid={row.InternalID} /> */}
																<InspectionView order={row} />
															</div>
															
														</td>
													}

													{/* Cost - Can also be used for Integers! Change onChangeValue method to use RestrictNumberInteger */}
													{(editmode && colstate.Cost) &&
														<td className={classes.flexgridinputcontainer}>
															<input
																type="number" step="0.01"
																ref={el => rowRefs.current[index + 'Cost'] = el}
																className={classes.flexgridinput}
																style={{ minWidth: '50px', textAlign: "right" }}
																onKeyDown={(event) => HandleKeyDown(event, index, 'Cost')}
																onKeyUp={(event) => onChangeValue(event, index, 'Cost')}
																onBlur={(event) => DetectBlankNumber(event, index, "Cost")}
																defaultValue={localstate.defaultvalues[index].Cost} />
														</td>
													}

													{(!editmode && colstate.Cost) &&
														<td className={classes.flexgridstaticcontainer}>
															{row.Cost}
														</td>
													}


													{/* Price */}
													{(editmode && colstate.Price) &&
														<td className={classes.flexgridinputcontainer}>
															<input
																type="number" step="0.01"
																ref={el => rowRefs.current[index + 'Price'] = el}
																className={classes.flexgridinput}
																style={{ minWidth: '50px', textAlign: "right" }}
																onKeyDown={(event) => HandleKeyDown(event, index, 'Price')}
																onKeyUp={(event) => onChangeValue(event, index, 'Price')}
																onBlur={(event) => DetectBlankNumber(event, index, "Price")}
																defaultValue={localstate.defaultvalues[index].Price} />
														</td>
													}

													{(!editmode && colstate.Price) &&
														<td className={classes.flexgridstaticcontainer}>
															{row.Price}
														</td>
													}

													{/* Margin */}
													{(colstate.Margin) &&
														<td className={classes.flexgridstaticcontainer}>
															<span ref={el => rowRefs.current[index + "Margin"] = el}>
																{row.Margin}
															</span>
														</td>
													}

													{/* SomePositiveInteger */}
													{(editmode && colstate.SomePositiveInteger) &&
														<td className={classes.flexgridinputcontainer}>
															<input
																type="number" step="1"
																ref={el => rowRefs.current[index + 'SomePositiveInteger'] = el}
																className={classes.flexgridinput}
																style={{ minWidth: '50px', textAlign: "right" }}
																onKeyDown={(event) => HandleKeyDown(event, index, 'SomePositiveInteger')}
																onKeyUp={(event) => onChangeValue(event, index, 'SomePositiveInteger')}
																onBlur={(event) => DetectBlankNumber(event, index, "SomePositiveInteger")}
																defaultValue={localstate.defaultvalues[index].SomePositiveInteger} />
														</td>
													}

													{/* SomeBoolean */}
													{(colstate.SomeBoolean) &&
														<td>
															{/* Try not to use this version of a boolean checkbox. It incurs expensive rerenders.*/}
															<div style={{ padding: "3px 4px 1px 4px" }}>
																	<input type="checkbox"
																		ref={el => rowRefs.current[index + "SomeBoolean"] = el}
																		checked={row.SomeBoolean === 1}
																		onChange={(event) => onChangeValue(event, index, "SomeBoolean")}
																	/>
																	{row.SomeBoolean}
																</div>
														</td>
													}

													{/* SomeSelectable */}
													{(colstate.SomeSelectable) &&
														<td>
															<FormControl className={classes.formControl} variant="standard">
																{/* <InputLabel id="demo-controlled-open-select-label">Optional Label</InputLabel> */}
																{/* Need: Set default value if row.SomeSelectable is null. */}
																{/* Why: Some of the values may be momentarily null for some reason - DB fetch, rerender? */}

																{/* Override icon prop class to our custom 'nodisplay' to remove arrowdown icon for this select */}
																{/* Override select prop class with new padding specs */}

																<Select
																	defaultValue={row.SomeSelectableDefault ? row.SomeSelectableDefault : "--"} disableUnderline
																	onChange={(event) => onChangeValue(event, index, "SomeSelectable")}
																	classes={{
																		icon: classes.nodisplay,
																		select: classes.selectpadding
																	}}
																>
																	<MenuItem value="--">
																		<em>None</em>
																	</MenuItem>
																	<MenuItem value={10}>Ten</MenuItem>
																	<MenuItem value={"A"}>
																		<Chip size="small" label="A" clickable className={classes.gradea} />
																	</MenuItem>
																	<MenuItem value={"B"}>
																		<Chip size="small" label="B" clickable className={classes.gradeb} />
																	</MenuItem>
																	<MenuItem value={"C"}>
																		<Chip size="small" label="C" clickable className={classes.gradec} />
																	</MenuItem>
																	<MenuItem value={"Bad"}>
																		<Chip size="small" label="Bad" clickable className={classes.gradebad} />
																	</MenuItem>
																	<MenuItem value={"Repair"}>
																		<Chip size="small" label="Repair" clickable className={classes.graderepair} />
																	</MenuItem>
																	<MenuItem value={"Scrap"}>
																		<Chip size="small" label="Scrap" clickable className={classes.gradescrap} />
																	</MenuItem>
																</Select>
															</FormControl>
														</td>
													}

												
													{/* NewCheckbox */}
													{(colstate.NewCheckbox) &&
														<React.Fragment>
															{(editmode) &&
																<td>													
																	<div style={{ padding: "4px 4px 1px 4px", textAlign:"center" }}>
																		<Checkbox
																			className={classes.gridcheckbox}
																			disableRipple
																			color="default"
																			defaultChecked={row.NewCheckbox===1 ? true : false}
																			checkedIcon={<span className={classes.icon+" "+classes.checkedIcon} />}
																			icon={<span className={classes.icon} />}
																			onChange={(event) => onChangeValue(event, index, "NewCheckbox")}
																		/>
																	</div>
																</td>
															}

															{(!editmode) &&
																<td>
																	<div style={{ padding: "4px 4px 1px 4px", textAlign: "center" }}>
																		<Checkbox
																			className={classes.gridcheckbox}
																			disabled
																			disableRipple
																			color="default"
																			defaultChecked={row.NewCheckbox === 1 ? true : false}
																			checkedIcon={<span className={classes.icon+" "+classes.checkedIcon} />}
																			icon={<span className={classes.icon} />}
																			onChange={(event) => onChangeValue(event, index, "NewCheckbox")}
																		/>
																	</div>
																</td>
															}
														</React.Fragment>
													}



													{/* Date */}
													{(colstate.Date) &&
														<td className={classes.flexgridstaticcontainer}>
															UTC: {row.Date}<br></br>
															Localized: <Moment tz="America/New_York" element='span' format="MMMM D, YYYY [at] h:mma">{row.Date}</Moment><br></br>
															Bermuda: <Moment tz="Atlantic/Bermuda" element='span' format="MMMM D, YYYY [at] h:mma">{row.Date}</Moment><br></br>
															Los Angeles: <Moment tz="America/Los_Angeles" element='span' format="MMMM D, YYYY [at] h:mma">{row.Date}</Moment><br></br>
														</td>
													}

												</tr>
												{/* Try: conditional for any render whatsoever! */}
												{(row.ExpandRow === true) &&
													<tr>
														<td colSpan="100%">
															<div style={{ margin: "25px" }}>
																Expanded column container!
														</div>
														</td>
													</tr>
												}
											</React.Fragment>

										)
									}
									)
								}
								{(localstate.griditems.length === 0) &&
									<tr className="flexgridrow"><td colSpan="100%"
										style={{ padding: "12px", fontSize: "18px" }}>No Results</td></tr>
								}
							</tbody>
						}
						{(localstate.dbreload) &&
							<tbody>
								<tr>
									<td colSpan="100%">
										<div style={{padding:"20px", textAlign:"center", margin:"auto"}}>
											<CircularProgress />
										</div>
									</td>
								</tr>
							</tbody>
						}
					</table> 
				</div>
				
				
				{(localstate.totalitems>localstate.rowsperpage) &&
					<TablePagination className={classes.paginationalign}
						SelectProps={
							(editmode && localstate.rowsperpage>=100) ? { disabled: true } : { disabled: false }
						}
						component="div"
						count={localstate.totalitems}
						page={localstate.page}
						onPageChange={handleChangePage}
						rowsPerPage={localstate.rowsperpage}
						onRowsPerPageChange={handleChangeRowsPerPage}
						rowsPerPageOptions={[50, 100, 250]}
					/>
				}	

			</div>
		</LocalizationProvider>
    );
}

export default OrderInspection;